import { useLazyQuery } from '@apollo/client';
import { SearchType } from '__generated__/graphql';
import { getIdFromUrn } from 'common/utils/urn';
import { ApiResourceType } from 'interfaces/DataModel/ApiResource';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { GET_SAVED_SEARCH_SIMPLE } from 'queries/getSavedSearch';
import { useEffect, useMemo } from 'react';
import useFilterStore from 'stores/filterStore';
import useStore from 'stores/zustandStore';
import { isSearchQueryEqual } from 'utils/searchV2/searchV2';
import { getModifiedCompanySortValueAfterValidationCheck, getModifiedPeopleSortValueAfterValidationCheck } from 'utils/sort';
import { getResourceTypeFromURL } from 'utils/utilities';
import useDashboardLocation from './useDashboardLocation';
import useSearchQuery from './useSearchQuery';
const useFetchSingleSavedSearch = () => {
    const { initialSearchFilterGroupQuery } = useSearchQuery();
    const editFilterStore = useFilterStore((state) => state.editFilterStore);
    const resourceType = getResourceTypeFromURL();
    const entityType = resourceType === ApiResourceType.CompaniesList
        ? EntityListType.COMPANY_SAVED_SEARCH
        : EntityListType.PEOPLE_SAVED_SEARCH;
    const { urn, location } = useDashboardLocation();
    const isSavedSearch = useMemo(() => urn?.includes('urn:harmonic:saved_search'), [urn]);
    const [fetchSavedSearch, { data, loading: fetchLoading, called, error }] = useLazyQuery(GET_SAVED_SEARCH_SIMPLE, {
        fetchPolicy: 'cache-first'
    });
    useEffect(() => {
        if ((entityType === EntityListType.COMPANY_SAVED_SEARCH ||
            entityType === EntityListType.PEOPLE_SAVED_SEARCH) &&
            isSavedSearch) {
            // this will fire whenever focused, but since the fetch uses cache only,
            // an additional network request should not be made in most cases.
            fetchSavedSearch({
                variables: {
                    idOrUrn: getIdFromUrn(urn ?? 'NA') ?? 'NA'
                }
            });
        }
    }, [urn, entityType]);
    const currentPageSavedSearch = useMemo(() => {
        if (!urn)
            return null;
        if (called && !fetchLoading && !error && data) {
            return data.getSavedSearch;
        }
        return null;
    }, [called, data, error, fetchLoading, urn]);
    // We update the saved search sort state in zustand upon fetching the saved search.
    // If for some reason the saved search response does not have a sort, we default to the initial sort.
    useEffect(() => {
        if (currentPageSavedSearch?.searchQuery?.sort) {
            if (currentPageSavedSearch.type === SearchType.PERSONS) {
                const { sortField, descending } = getModifiedPeopleSortValueAfterValidationCheck(currentPageSavedSearch.searchQuery.sort?.[0]?.sortField, currentPageSavedSearch.searchQuery.sort?.[0]?.descending);
                editFilterStore('peopleSavedSearchPageSort', {
                    ...currentPageSavedSearch.searchQuery.sort?.[0],
                    sortField,
                    descending
                });
                return;
            }
            // For company searches, since we are removing several sorts that we had previously, we need to check if the sort that is
            // stored on the backend is still valid. If not, we auto switch it to the default. We can remove this check once we do a backfill,
            // but it doesnt hurt to still double validate this on the frontend.
            const { sortField, descending } = getModifiedCompanySortValueAfterValidationCheck(currentPageSavedSearch.searchQuery.sort?.[0]?.sortField, currentPageSavedSearch.searchQuery.sort?.[0]?.descending);
            editFilterStore('savedSearchPageSort', {
                ...currentPageSavedSearch.searchQuery.sort?.[0],
                sortField: sortField,
                descending: descending
            });
        }
    }, [currentPageSavedSearch, editFilterStore, location, urn]);
    const companiesDashboardFilters = useFilterStore((state) => state.companiesDashboardFilters);
    const peopleDashboardFilters = useFilterStore((state) => state.peopleDashboardFilters);
    const savedSearchFiltersCollection = useFilterStore((state) => state.savedSearchFiltersCollection);
    const getCurrentPersistedQuery = (entityType, savedSearchId) => {
        const useSavedSearchOrLocalStorage = savedSearchId
            ? (savedSearchFiltersCollection &&
                savedSearchFiltersCollection[savedSearchId]) ??
                initialSearchFilterGroupQuery
            : initialSearchFilterGroupQuery;
        if (!isSavedSearch) {
            if (entityType === EntityListType.COMPANY_SAVED_SEARCH) {
                return companiesDashboardFilters ?? initialSearchFilterGroupQuery;
            }
            if (entityType === EntityListType.PEOPLE_SAVED_SEARCH) {
                return peopleDashboardFilters ?? initialSearchFilterGroupQuery;
            }
        }
        return useSavedSearchOrLocalStorage;
    };
    const currentPersistedQuery = isSavedSearch &&
        getCurrentPersistedQuery(entityType, parseInt(getIdFromUrn(urn)));
    // Original query from the api itself
    const activeTabSearchModelFromApi = useStore((state) => state.activeTabSearchModelFromApi);
    const isCurrentPageSavedSearchEdited = useMemo(() => isSavedSearch
        ? !isSearchQueryEqual({
            filter_group: activeTabSearchModelFromApi.filter_group,
            controlled_filter_group: activeTabSearchModelFromApi.controlled_filter_group
        }, currentPersistedQuery)
        : false, [activeTabSearchModelFromApi, currentPersistedQuery]);
    return {
        loading: fetchLoading,
        getCurrentPersistedQuery,
        currentPageSavedSearch,
        isCurrentPageSavedSearchEdited,
        error
    };
};
export default useFetchSingleSavedSearch;
