import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import { getBackgroundColorClassFromShorthandAndIntensity, getContentColorClassFromShorthandAndIntensity, getIconColorClassFromShorthandAndIntensity } from 'utils/design';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
const Tag = ({ size = 'comfortable', label, leadingIcon, color = 'neutral', intensity = 'subtle', whiteSpace = 'pre', onClick, onRemove, truncate, shrink = true, dataTestId }) => {
    const LeadingIcon = leadingIcon;
    const backgroundColorClass = getBackgroundColorClassFromShorthandAndIntensity(color, intensity);
    const contentColorClass = getContentColorClassFromShorthandAndIntensity(color, intensity);
    const iconColorClass = getIconColorClassFromShorthandAndIntensity(color, intensity);
    return (_jsxs("div", { onClick: onClick, "data-testid": dataTestId, className: classNames('inline-flex rounded-br20 gap-g10 items-center justify-center max-w-full', shrink && 'flex-shrink-0', truncate && 'truncate', {
            'py-p10 px-p30 typography-label-default-default': size == 'comfortable',
            'py-p00 px-p20 typography-label-default-default': size == 'compact'
        }, onClick ? 'cursor-pointer' : 'cursor-default', //currently cursor pointer gets passed down from parent anyways so this is redundant
        onRemove && 'pr-p10', backgroundColorClass, contentColorClass), children: [LeadingIcon && (_jsx("div", { className: iconColorClass, children: _jsx(LeadingIcon, { className: "w-4 h-4" }) })), _jsx("p", { className: classNames(!truncate && 'line-clamp-1', truncate && 'truncate', whiteSpace === 'pre' && 'whitespace-pre'), children: label }), onRemove && (_jsx("button", { className: classNames({
                    'w-5 h-5': size == 'comfortable',
                    'w-4 h-4': size == 'compact'
                }, 'flex items-center justify-center', 'hover:bg-int-overlay-secondary-hover rounded-br20', 'focus-visible:shadow-int-focus-outside', iconColorClass), "data-testid": "tag-remove-icon", onClick: (e) => {
                    e.stopPropagation();
                    onRemove();
                }, children: _jsx(CloseIcon, { className: "fill-current" }) }))] }));
};
export default Tag;
