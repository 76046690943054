import { useMutation } from '@apollo/client';
import { ListNamedViewDisplayType } from '__generated__/graphql';
import { setSelectedNamedViewIdQueryParam } from 'utils/namedViews';
import { displayToast } from 'utils/toasts';
import { SPLITS } from '../utils/constants';
import { useLocalSearchState } from './useAppState';
import useFlags from './useFlags';
const useUpsertWatchlistNamedViews = ({ upsertMutation, watchlistFragment, getSelectedNamedViewIndex, namedViewCacheIdName, watchlistFragmentName, backendResponseKey, watchlistCacheIdName, watchlist, customFieldCacheIdName, containsKanbanView, selectedNamedView, kanbanViewTypeDefaultColumns = [], gridViewTypeDefaultColumns = [] }) => {
    const { enabled: enableSaveUX } = useFlags(SPLITS.enableSaveUX);
    const { updateLocalSearch } = useLocalSearchState();
    const [upsertWatchlistNamedView, { loading }] = useMutation(upsertMutation);
    const upsertNamedView = async ({ variables, namedViewUrn, watchlistUrn, skipUpsertLocalSearch = false }) => {
        const selectedNamedViewIndex = watchlist?.namedViews?.findIndex((namedView) => namedView.entityUrn === namedViewUrn) ?? 0;
        const namedViewsId = watchlist?.namedViews?.[selectedNamedViewIndex]?.id;
        let modifiedVariables = variables;
        // when the kanban is enabled for person, we won't need to do type casting
        let groupByFieldUrn = undefined;
        if (containsKanbanView) {
            groupByFieldUrn =
                variables?.groupByField ??
                    watchlist?.namedViews?.[selectedNamedViewIndex]?.groupByField?.urn;
        }
        let visibleColumns = variables.visibleColumns ??
            watchlist?.namedViews?.[selectedNamedViewIndex]?.visibleColumns ??
            null;
        if (variables.displayType && !visibleColumns) {
            visibleColumns =
                variables.displayType === ListNamedViewDisplayType.GRID
                    ? gridViewTypeDefaultColumns
                    : kanbanViewTypeDefaultColumns;
            visibleColumns = [
                ...(watchlist?.customFields?.map((cf) => cf.urn) ?? []),
                ...visibleColumns
            ];
            modifiedVariables = {
                ...variables,
                visibleColumns
            };
        }
        let optimistcResponseObj = {
            __typename: namedViewCacheIdName,
            id: namedViewsId,
            entityUrn: watchlist?.namedViews?.[selectedNamedViewIndex]?.entityUrn ?? '',
            name: variables.name ??
                watchlist?.namedViews?.[selectedNamedViewIndex]?.name ??
                '',
            visibleColumns,
            searchQuery: variables.searchQuery ??
                watchlist?.namedViews?.[selectedNamedViewIndex]?.searchQuery ??
                null
        };
        if (containsKanbanView) {
            optimistcResponseObj = {
                ...optimistcResponseObj,
                displayType: variables.displayType ??
                    watchlist?.namedViews?.[selectedNamedViewIndex]?.displayType ??
                    null,
                groupByField: groupByFieldUrn
                    ? {
                        __typename: customFieldCacheIdName,
                        urn: groupByFieldUrn
                    }
                    : null,
                hideEmptyColumns: variables?.hideEmptyColumns ??
                    watchlist?.namedViews?.[selectedNamedViewIndex]?.hideEmptyColumns ??
                    false
            };
        }
        if (enableSaveUX && !skipUpsertLocalSearch) {
            updateLocalSearch(namedViewUrn, optimistcResponseObj);
            return;
        }
        const res = await upsertWatchlistNamedView({
            variables: {
                watchlistUrn: watchlistUrn,
                namedViewUrn: namedViewUrn,
                namedViewInput: modifiedVariables
            },
            optimisticResponse: {
                [backendResponseKey]: optimistcResponseObj
            },
            onError: () => {
                displayToast({
                    mode: 'error',
                    primaryText: 'Failed to update named view'
                });
            }
        });
        if (!res.data?.[backendResponseKey]) {
            return displayToast({
                mode: 'error',
                primaryText: 'Failed to update named view'
            });
        }
        return res.data[backendResponseKey];
    };
    const duplicateNamedView = async (urn) => {
        const existingNamedView = watchlist?.namedViews?.find((namedView) => namedView.entityUrn === urn);
        let createNamedViewInput = {
            name: `${existingNamedView?.name} (Copy)`,
            visibleColumns: existingNamedView?.visibleColumns,
            searchQuery: existingNamedView?.searchQuery,
            displayType: existingNamedView?.displayType
        };
        if (containsKanbanView) {
            createNamedViewInput = {
                ...createNamedViewInput,
                groupByField: existingNamedView?.groupByField
                    ?.urn
            };
        }
        return createNamedView({
            variables: createNamedViewInput,
            watchlistUrn: watchlist?.entityUrn ?? ''
        });
    };
    const upsertCurrentSelectedNamedView = async ({ variables, skipUpsertLocalSearch = false }) => {
        const selectedNamedViewIndex = getSelectedNamedViewIndex(watchlist, selectedNamedView);
        return upsertNamedView({
            variables,
            namedViewUrn: watchlist?.namedViews?.[selectedNamedViewIndex]?.entityUrn,
            watchlistUrn: watchlist?.entityUrn ?? '',
            skipUpsertLocalSearch
        });
    };
    // This function is used to create a new named view with the filter display type copied over from the existing named view.
    // Until we have save UX, create button will port over things. We can remove it later
    const createNamedViewWithFilterDisplayCopiedOver = async ({ variables, watchlistUrn }) => {
        const selectedNamedViewIndex = getSelectedNamedViewIndex(watchlist, selectedNamedView);
        const existingNamedView = watchlist?.namedViews?.[selectedNamedViewIndex];
        let createNamedViewInput = {
            visibleColumns: existingNamedView?.visibleColumns,
            searchQuery: existingNamedView?.searchQuery
        };
        createNamedViewInput = {
            ...createNamedViewInput,
            ...variables
        };
        return createNamedView({
            variables: createNamedViewInput,
            watchlistUrn: watchlistUrn
        });
    };
    const createNamedView = async ({ variables, watchlistUrn }) => {
        const defaultColumns = variables.displayType === ListNamedViewDisplayType.GRID
            ? gridViewTypeDefaultColumns
            : kanbanViewTypeDefaultColumns;
        let visibleColumns = defaultColumns;
        visibleColumns = [
            ...(watchlist?.customFields?.map((cf) => cf.urn) ?? []),
            ...defaultColumns
        ];
        const res = await upsertWatchlistNamedView({
            variables: {
                watchlistUrn,
                namedViewUrn: null,
                namedViewInput: {
                    ...variables,
                    visibleColumns
                }
            },
            update: (cache, { data }) => {
                const cachedWatchlist = cache.readFragment({
                    id: `${watchlistCacheIdName}:${watchlist?.id}`,
                    fragment: watchlistFragment,
                    fragmentName: watchlistFragmentName
                });
                const updatedWatchlist = {
                    ...cachedWatchlist,
                    namedViews: [
                        ...(cachedWatchlist?.namedViews ?? []),
                        data?.[backendResponseKey]
                    ]
                };
                cache.writeFragment({
                    id: `${watchlistCacheIdName}:${watchlist?.id}`,
                    fragment: watchlistFragment,
                    fragmentName: watchlistFragmentName,
                    data: updatedWatchlist
                });
                setSelectedNamedViewIdQueryParam(data?.[backendResponseKey]?.id);
            }
        });
        return res.data?.[backendResponseKey];
    };
    return {
        loading,
        upsertCurrentSelectedNamedView,
        upsertNamedView,
        createNamedView: createNamedViewWithFilterDisplayCopiedOver,
        duplicateNamedView
    };
};
export default useUpsertWatchlistNamedViews;
