import { compact } from 'lodash';
import { WATCHLIST_WITH_PEOPLE } from 'queries/getWatchlistWithPeople';
import getPersonsByIds from '../queries/getPersonsByIds';
import { usePeopleWatchlistSearchVariables } from './usePeopleWatchlistSearchVariables';
import { useRefetchWatchlistResults } from './useRefetchWatchlistResults';
export const useRefetchPeopleWatchlistResults = () => {
    const variables = usePeopleWatchlistSearchVariables();
    return useRefetchWatchlistResults({
        query: WATCHLIST_WITH_PEOPLE,
        extendedQuery: getPersonsByIds,
        variables,
        getDataPath: (data) => ({
            edges: data?.getPeopleWatchlistByIdOrUrn?.personEntries?.edges?.map((edge) => ({
                node: { person: { id: edge.node.person.id } }
            })) || [],
            pageInfo: {
                hasNextPage: data?.getPeopleWatchlistByIdOrUrn?.personEntries?.pageInfo
                    ?.hasNextPage,
                endCursor: data?.getPeopleWatchlistByIdOrUrn?.personEntries?.pageInfo?.endCursor
            }
        }),
        getIdsFromData: (data) => compact(data.getPeopleWatchlistByIdOrUrn?.personEntries.edges.map((edge) => edge.node.person.id)),
        getUrnsFromExtendedData: (data) => data?.getPersonsByIds?.map((person) => `urn:harmonic:person:${person?.id}`) ?? []
    });
};
