import { useApolloClient } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import { useNavigate } from 'react-router-dom';
import useStore from 'stores/zustandStore';
import { EntityListType, EntityURNTypeToDashboardPath } from '../interfaces/SearchModel/Search';
import { ADD_COMPANY_WATCHLIST_ERROR_MESSAGE } from '../utils/constants';
import { logger } from '../utils/logger';
import { addWatchlist } from '../utils/midtierApi';
import { displayToast } from '../utils/toasts';
export const useCreateCompanyWatchlist = () => {
    const client = useApolloClient();
    const editStoreData = useStore((state) => state.editStoreData);
    const navigate = useNavigate();
    const createCompanyWatchlist = async (name, disableNavigation = false, disableToast = false, onSuccess) => {
        const payload = {
            name,
            shared_with_team: false,
            companies: []
        };
        try {
            const response = await addWatchlist(client, payload);
            const entityListId = getIdFromUrn(response.urn);
            if (onSuccess) {
                await onSuccess({
                    entityListId: entityListId ?? '',
                    entityListName: name
                });
            }
            const notificationMessage = 'New list successfully created';
            if (disableNavigation) {
                if (!disableToast) {
                    displayToast({
                        primaryText: notificationMessage,
                        secondaryText: 'Go to list',
                        link: `/dashboard/watchlist/${response.urn}`
                    });
                }
                return;
            }
            editStoreData('pageTitle', name);
            if (!disableToast) {
                displayToast({
                    primaryText: notificationMessage,
                    mode: 'success'
                });
            }
            const toNavigateRoute = `/dashboard/watchlist/urn:harmonic:${EntityURNTypeToDashboardPath[EntityListType.COMPANY_WATCHLIST]}:${entityListId}`;
            navigate(toNavigateRoute);
        }
        catch (error) {
            logger.error('Company watchlist create error', {
                error
            });
            displayToast({
                primaryText: ADD_COMPANY_WATCHLIST_ERROR_MESSAGE,
                mode: 'error'
            });
        }
    };
    return { createCompanyWatchlist };
};
