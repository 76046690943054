import { gql } from '@apollo/client';
const GetUsersInNetworkForCompany_User = gql `
  fragment GetUsersInNetworkForCompany_User on User {
    email
    name
  }
`;
const getUsersInNetworkForCompany = gql `
  ${GetUsersInNetworkForCompany_User}
  query GetUsersInNetworkForCompany($id: Int!) {
    getCompanyById(id: $id) {
      id
      usersInNetwork {
        user {
          ...GetUsersInNetworkForCompany_User
        }
      }
    }
  }
`;
export default getUsersInNetworkForCompany;
