// This header is part of our new flow to fetch columnOrder from postgres.
// When it's fully rulled out, this hook should be cleaned up to remove the old flow
import CompanyColumnsConfig, { CompanyColumnsUrnToValueMap, CompanyColumnsValueToObjectMap } from 'interfaces/CompanyColumnsConfig';
import PersonColumnsConfig, { PersonColumnsUrnToValueMap, PersonColumnsValueToObjectMap } from 'interfaces/PersonColumnsConfig';
export const getSelectedCompanyNamedViewIndex = (companyWatchlist, companyListSelectedNamedView) => {
    const selectedNamedViewUrn = companyListSelectedNamedView?.[companyWatchlist?.entityUrn ?? ''];
    if (!selectedNamedViewUrn)
        return 0;
    return (companyWatchlist?.namedViews?.findIndex((view) => view?.entityUrn === selectedNamedViewUrn) ?? 0);
};
export const getSelectedPersonNamedViewIndex = (peopleWatchlist, personListSelectedNamedView) => {
    const selectedNamedViewUrn = personListSelectedNamedView?.[peopleWatchlist?.entityUrn ?? ''];
    if (!selectedNamedViewUrn)
        return 0;
    return (peopleWatchlist?.namedViews?.findIndex((view) => view?.entityUrn === selectedNamedViewUrn) ?? 0);
};
export const getCompanyWatchlistHeadersFromNamedViews = (companyWatchlist, selectedNamedViewIndex) => {
    const customFieldHeaders = companyWatchlist?.customFields ?? [];
    const CustomFieldHeadersUrnToValueMap = {};
    customFieldHeaders.forEach((header) => {
        CustomFieldHeadersUrnToValueMap[header.urn] = {
            label: header.name,
            value: header.urn
        };
    });
    const CompanyColumnsCombinedUrnToValueMap = {
        ...CustomFieldHeadersUrnToValueMap,
        ...CompanyColumnsUrnToValueMap
    };
    const columnUrns = companyWatchlist?.namedViews?.[selectedNamedViewIndex]?.visibleColumns ??
        [];
    const filteredColumnUrns = columnUrns.filter((urn) => urn !== CompanyColumnsConfig.NAME.urn);
    let columnHeaders = filteredColumnUrns.map((urn) => ({
        id: CompanyColumnsCombinedUrnToValueMap[urn]?.value,
        name: CompanyColumnsCombinedUrnToValueMap[urn]?.label,
        isVisibleOnTable: true
    }));
    columnHeaders = [
        {
            id: CompanyColumnsConfig.NAME.value,
            name: CompanyColumnsConfig.NAME.label,
            isVisibleOnTable: true
        },
        ...columnHeaders
    ];
    return columnHeaders;
};
export const getPeopleWatchlistHeadersFromNamedViews = (peopleWatchlist, selectedNamedViewIndex) => {
    const customFieldHeaders = peopleWatchlist?.customFields ?? [];
    const CustomFieldHeadersUrnToValueMap = {};
    customFieldHeaders.forEach((header) => {
        CustomFieldHeadersUrnToValueMap[header.urn] = {
            label: header.name,
            value: header.urn
        };
    });
    const PersonColumnsCombinedUrnToValueMap = {
        ...CustomFieldHeadersUrnToValueMap,
        ...PersonColumnsUrnToValueMap
    };
    const columnUrns = peopleWatchlist?.namedViews?.[selectedNamedViewIndex]?.visibleColumns ?? [];
    const filteredColumnUrns = columnUrns.filter((urn) => urn !== PersonColumnsConfig.FULL_NAME.urn);
    let columnHeaders = filteredColumnUrns.map((urn) => ({
        id: PersonColumnsCombinedUrnToValueMap[urn]?.value,
        name: PersonColumnsCombinedUrnToValueMap[urn]?.label,
        isVisibleOnTable: true
    }));
    columnHeaders = [
        {
            id: PersonColumnsConfig.FULL_NAME.value,
            name: PersonColumnsConfig.FULL_NAME.label,
            isVisibleOnTable: true
        },
        ...columnHeaders
    ];
    return columnHeaders;
};
export const getCompanySavedSearchColumnOrders = (namedViews) => {
    const filteredNamedViews = namedViews.filter((view) => view !== CompanyColumnsConfig.NAME.urn);
    let columnHeaders = filteredNamedViews.map((urn) => ({
        id: CompanyColumnsUrnToValueMap[urn]?.value,
        name: CompanyColumnsUrnToValueMap[urn]?.label,
        isVisibleOnTable: true
    }));
    columnHeaders = [
        {
            id: CompanyColumnsConfig.NAME.value,
            name: CompanyColumnsConfig.NAME.label,
            isVisibleOnTable: true
        },
        ...columnHeaders
    ];
    return columnHeaders;
};
export const getPeopleSavedSearchColumnOrders = (namedViews) => {
    const filteredNamedViews = namedViews.filter((view) => view !== PersonColumnsConfig.FULL_NAME.urn);
    let columnHeaders = filteredNamedViews.map((urn) => ({
        id: PersonColumnsUrnToValueMap[urn]?.value,
        name: PersonColumnsUrnToValueMap[urn]?.label,
        isVisibleOnTable: true
    }));
    columnHeaders = [
        {
            id: PersonColumnsConfig.FULL_NAME.value,
            name: PersonColumnsConfig.FULL_NAME.label,
            isVisibleOnTable: true
        },
        ...columnHeaders
    ];
    return columnHeaders;
};
export const convertCompanyColumnsConfigToUrns = (columns) => {
    return columns
        .filter((col) => col.id)
        .map((column) => {
        return CompanyColumnsValueToObjectMap[column.id]?.urn;
    })
        .filter((col) => col);
};
export const convertCompanyListColumnsConfigToUrns = (columns) => {
    return columns
        .filter((col) => col.id)
        .map((column) => {
        if (column.id.startsWith('urn:harmonic:company_list_custom_field')) {
            return column.id;
        }
        return CompanyColumnsValueToObjectMap[column.id]?.urn;
    })
        .filter((col) => col);
};
export const convertPeopleListColumnsConfigToUrns = (columns) => {
    return columns
        .filter((col) => col.id)
        .map((column) => {
        if (column.id.startsWith('urn:harmonic:person_list_custom_field')) {
            return column.id;
        }
        return PersonColumnsValueToObjectMap[column.id]?.urn;
    })
        .filter((col) => col);
};
export const convertPersonColumnsConfigToUrns = (columns) => {
    return columns
        .filter((col) => col.id)
        .map((column) => {
        return PersonColumnsValueToObjectMap[column.id]?.urn;
    })
        .filter((col) => col);
};
export const COMPANY_WATCHLIST_GRID_VIEW_DEFAULT_COLUMNS = [
    'urn:harmonic:company_field:company_description',
    'urn:harmonic:company_field:company_highlights',
    'urn:harmonic:company_field:company_website_url',
    'urn:harmonic:company_field:company_team',
    'urn:harmonic:company_field:company_customers_connections',
    'urn:harmonic:company_field:company_customers_connections_last_email_at',
    'urn:harmonic:company_field:company_customers_connections_last_meeting_at',
    'urn:harmonic:company_field:company_team_notes',
    'urn:harmonic:company_field:company_investor_names',
    'urn:harmonic:company_field:company_funding_total',
    'urn:harmonic:company_field:company_last_funding_type',
    'urn:harmonic:company_field:company_last_funding_date',
    'urn:harmonic:company_field:company_headcount',
    'urn:harmonic:company_field:company_headcount_percent_change_90d_ago'
];
export const COMPANY_WATCHLIST_KANBAN_VIEW_DEFAULT_COLUMNS = [
    'urn:harmonic:company_field:company_description',
    'urn:harmonic:company_field:company_website_url',
    'urn:harmonic:company_field:company_funding_total',
    'urn:harmonic:company_field:company_last_funding_date',
    'urn:harmonic:company_field:company_web_traffic_value_90d_ago',
    'urn:harmonic:company_field:company_team_notes'
];
export const PERSON_LIST_DEFAULT_COLUMNS = [
    'urn:harmonic:person_field:person_customers_connections',
    'urn:harmonic:person_field:person_emails',
    'urn:harmonic:person_field:person_linkedin_url',
    'urn:harmonic:person_field:person_linkedin_headline',
    'urn:harmonic:person_field:person_highlights_category_list',
    'urn:harmonic:person_field:person_position_title',
    'urn:harmonic:person_field:person_position_company_id',
    'urn:harmonic:person_field:person_position_company_tags',
    'urn:harmonic:person_field:person_last_company_id',
    'urn:harmonic:person_field:person_location',
    'urn:harmonic:person_field:person_education_experience_school'
];
export const convertCompanyListNamedViewIdToUrn = (namedViewId) => {
    return `urn:harmonic:company_list_named_view:${namedViewId}`;
};
export const convertPersonListNamedViewIdToUrn = (namedViewId) => {
    return `urn:harmonic:person_list_named_view:${namedViewId}`;
};
const SELECTED_NAMED_VIEW_QUERY_STRING = 'selectedNamedView';
export const getSelectedNamedViewIdByQueryParam = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const selectedNamedView = urlParams.get(SELECTED_NAMED_VIEW_QUERY_STRING);
    return selectedNamedView;
};
export const setSelectedNamedViewIdQueryParam = (namedViewId) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(SELECTED_NAMED_VIEW_QUERY_STRING, `${namedViewId}`);
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, '', newUrl);
};
export const removeSelectedNamedViewIdQueryParam = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(SELECTED_NAMED_VIEW_QUERY_STRING);
    let newUrl = window.location.pathname;
    if (urlParams.toString()) {
        newUrl += `?${urlParams.toString()}`;
    }
    window.history.pushState({}, '', newUrl);
};
