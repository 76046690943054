import { logout } from 'actions/authActions';
import axios from 'axios';
import { LogoutReason } from 'interfaces/Auth';
import { ApiResourceType } from 'interfaces/DataModel/ApiResource';
import { getFirebaseToken } from 'actions/fetchActions';
import { config } from 'config/config';
import { API_V2_SEARCH_FIELD_SPEC } from './constants';
import { getUser } from './midtierApi';
import { useAppStore } from '../hooks/useAppStore';
import { logger } from './logger';
/**
 * Search Model
 */
export const makeGraphqlMutationWithApolloClient = async (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
client, mutation, variables, refetchQueries, update) => {
    const response = await client.mutate({
        mutation,
        variables,
        refetchQueries,
        update
    });
    return response;
};
/**
 * API Resources
 */
const getApiResource = (apiUrl, requestParams, preFetch, // fetch in background, do not show loading in UI
// eslint-disable-next-line @typescript-eslint/no-explicit-any
requestBody, cancelToken, requestHeaders) => {
    const axiosRequest = requestBody
        ? axios.post(apiUrl, requestBody, {
            params: requestParams,
            cancelToken: cancelToken,
            headers: requestHeaders
        })
        : axios.get(apiUrl, {
            params: requestParams,
            cancelToken: cancelToken,
            headers: requestHeaders
        });
    return axiosRequest.then((value) => {
        if (value.data) {
            return Promise.resolve(value.data);
        }
        // No data in response
        else {
            logger.error(`Data fetch failure: non 200 response or empty data`);
            // setError(DATA_FETCH_ERROR_MESSAGE);
            return Promise.reject('No data in response');
        }
    }, (error) => {
        if (error.response?.status === 401) {
            // If unauthorized response, then session has expired. Log user out.
            logout(LogoutReason.SessionExpired);
        }
        else if (error.response?.status === 404) {
            null;
        }
        else if (error.response?.status === 403) {
            // Pass for now
            // TODO: Move all error handling out of API util layer
            null;
        }
        else if (axios.isCancel(error)) {
            null;
        }
        else {
            const user = useAppStore.getState().auth.user;
            const formattedUser = user
                ? {
                    email: user.email,
                    id: user.user_id,
                    username: user.displayName
                }
                : undefined;
            logger.error(error.message || 'Unknown error', {
                error,
                user: formattedUser,
                extra: {
                    apiUrl,
                    requestBody: JSON.stringify(requestBody),
                    requestHeaders,
                    requestParams
                }
            });
            // setError(DATA_FETCH_ERROR_MESSAGE);
        }
        return Promise.reject(error);
    });
};
export const updateCompanyCustomText = async (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
client, companyId, 
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
payload, cancelToken) => {
    const { apikey } = await getUser();
    const { custom_text, scope } = payload;
    const apiUrl = `${config.BASE_MIDTIER_API_URL}companies/set_custom_text/${companyId}?custom_text=${custom_text}&scope=${scope}`;
    return axios
        .put(apiUrl, payload, {
        headers: { apikey },
        cancelToken
    })
        .then((res) => {
        const updatedField = scope === 'USER' ? 'userNotes' : 'teamNotes';
        if (res.status === 200) {
            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'Company',
                    id: companyId
                }),
                fields: {
                    [updatedField]: () => custom_text
                },
                broadcast: false // so the grid doesn't re-render and jump to the top
            });
        }
        return res.data;
    });
};
export const getPeopleListSearchFieldSpec = async (requestParams, preFetch = true) => {
    const firebaseToken = await getFirebaseToken();
    const headers = {
        authorization: firebaseToken
    };
    const apiUrl = `${config.BASE_MIDTIER_API_URL}search/searchFieldSpec?search_type=PERSONS`;
    return getApiResource(apiUrl, requestParams, preFetch, null, undefined, headers);
};
export const getCompaniesListSearchFieldSpec = async (requestParams, preFetch = true) => {
    const firebaseToken = await getFirebaseToken();
    const headers = {
        authorization: firebaseToken
    };
    const apiUrl = `${config.BASE_MIDTIER_API_URL}${API_V2_SEARCH_FIELD_SPEC}`;
    return getApiResource(apiUrl, { ...requestParams, search_type: ApiResourceType.CompaniesList }, preFetch, undefined, undefined, headers);
};
export const getCapTableForCompanyId = async (companyId) => {
    const requestUrl = `https://assets.harmonic.ai/captables/${companyId}/captables.json`;
    const res = await fetch(requestUrl);
    const jsonRes = await res.json();
    return JSON.parse(jsonRes.captable);
};
