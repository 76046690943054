import { jsx as _jsx } from "react/jsx-runtime";
import CompanyAttribute from 'harmonic-components/CompanyAttribute/CompanyAttribute';
import TruncatedList from 'harmonic-components/TruncatedList/TruncatedList';
import { shouldLinkCompanyId } from 'utils/company';
const CompanyEmployeesExperienceCellRenderer = (props) => {
    const companies = props.value;
    if (!companies || companies.length === 0) {
        return (_jsx("div", { className: "inline-block", children: _jsx("span", { className: "typography-paragraph text-content-muted", children: "No data" }) }));
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: companies.map((company, index) => (_jsx("div", { children: _jsx(CompanyAttribute, { src: company.logoUrl ?? '', href: shouldLinkCompanyId(company.id)
                        ? `/dashboard/company/${company.id}`
                        : '', name: company.name ?? '' }) }, index))) }) }));
};
export default CompanyEmployeesExperienceCellRenderer;
