import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddCompanyToAffinityButton from 'components/common/CompanyActions/AddCompanyToAffinityButton';
import AddCompanyToWatchlistButton from 'components/common/CompanyActions/AddCompanyToWatchlistButton';
import CompanyNotesButton from 'components/common/CompanyActions/CompanyNotesButton';
import LikeCompanyButton from 'components/common/CompanyActions/LikeCompanyButton';
import ReportDataIssue from 'components/common/ReportDataIssue';
import useFlags from 'hooks/useFlags';
import { ReportSourceSection, ReportSourceView } from 'interfaces/UserReportedDataIssue';
import React from 'react';
import { SPLITS } from 'utils/constants';
import { nonStealthNavbarOptions, stealthNavbarOptions } from '../CompanyHeader/CompanyHeader';
export const CompanyActions = ({ companyId, isStealthCompany, selectedTab }) => {
    const { enabled: enablePushToAffinity } = useFlags(SPLITS.enablePushToAffinity);
    const navbarOptions = isStealthCompany
        ? stealthNavbarOptions
        : nonStealthNavbarOptions;
    const currentOption = navbarOptions.find((option) => option.value === selectedTab)?.value;
    return (_jsxs("div", { className: "flex flex-row items-center gap-g20 lg:gap-g40", children: [_jsx(AddCompanyToWatchlistButton, { companyId: companyId }), enablePushToAffinity && (_jsx(AddCompanyToAffinityButton, { companyId: companyId, isDisabled: isStealthCompany ?? false, tooltipText: isStealthCompany
                    ? 'Only companies with a website URL can be added'
                    : undefined })), _jsx(LikeCompanyButton, { companyId: companyId }), _jsx(CompanyNotesButton, { companyId: companyId }), _jsx(ReportDataIssue, { showCircularBorder: true, reportParams: {
                    companyUrn: `urn:company:harmonic:${companyId}`,
                    reportSourceView: ReportSourceView.COMPANY,
                    reportSourceSection: ReportSourceSection[currentOption]
                } })] }));
};
