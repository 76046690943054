import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Tabs from 'harmonic-components/Tabs/Tabs';
import { CompanyActions } from './CompanyActions/CompanyActions';
export const StickyNavBar = ({ companyLogoUrl, companyId, companyName, selectedTab, filteredNavbarOptions, onTabChange }) => {
    return (_jsxs("div", { className: "flex flex-col gap-g50 w-full h-fit bg-transparent shadow-search px-p70 py-p50", children: [_jsxs("div", { className: "flex justify-between items-center", children: [_jsxs("div", { className: "flex gap-g50 items-center", children: [_jsx(Avatar, { src: companyLogoUrl ?? '', alt: "Company Logo", size: "large" }), _jsx("p", { className: classNames('typography-label text-content-title'), children: companyName })] }), _jsx(CompanyActions, { companyId: companyId, selectedTab: selectedTab })] }), _jsx(Tabs, { tabs: filteredNavbarOptions.map((navbar) => ({
                    ...navbar,
                    variation: 'label'
                })), selectedTabValue: selectedTab, onTabChange: (newTabValue) => onTabChange(newTabValue) })] }));
};
