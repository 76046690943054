import { useQuery } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import { useMemo } from 'react';
import { SPLITS } from '../utils/constants';
import { useLocalSearchState } from './useAppState';
import useDashboardLocation from './useDashboardLocation';
import useFlags from './useFlags';
export const useBaseFetchSingleWatchlist = ({ fetchPolicy = 'cache-first', query, location, transformData, skipUpsertLocalSearch = false }) => {
    const { searchByKey } = useLocalSearchState();
    const { enabled: enableSaveUX } = useFlags(SPLITS.enableSaveUX);
    const routeDetails = useDashboardLocation();
    const urn = routeDetails.urn;
    const isCorrectLocation = routeDetails.location === location;
    const id = getIdFromUrn(urn ?? null) || '';
    const { data, loading, error } = useQuery(query, {
        variables: {
            idOrUrn: id
        },
        skip: !isCorrectLocation,
        fetchPolicy,
        returnPartialData: true,
        notifyOnNetworkStatusChange: true
    });
    const transformedData = useMemo(() => {
        if (data && isCorrectLocation) {
            const watchlist = transformData(data);
            if (!skipUpsertLocalSearch && enableSaveUX) {
                const namedViews = watchlist?.namedViews?.map((namedView) => {
                    const localSearch = searchByKey[namedView.entityUrn];
                    if (!localSearch)
                        return namedView;
                    return {
                        ...namedView,
                        name: localSearch.name ?? namedView.name,
                        searchQuery: localSearch.searchQuery ?? namedView.searchQuery,
                        visibleColumns: localSearch.visibleColumns ?? namedView.visibleColumns,
                        displayType: localSearch.displayType ?? namedView.displayType,
                        hideEmptyColumns: localSearch.hideEmptyColumns ?? namedView.hideEmptyColumns,
                        groupByField: localSearch.groupByField ?? namedView.groupByField
                    };
                });
                return {
                    ...watchlist,
                    namedViews
                };
            }
            return watchlist;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, searchByKey, isCorrectLocation]);
    return {
        data: transformedData,
        loading: !data && loading,
        error
    };
};
