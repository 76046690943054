import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { ConnectionIntegrationType } from '__generated__/graphql';
import { ConnectionsBanner } from 'components/QueryBuilderV2/Common/ConnectionsBanner';
import UserConnectionsTooltip from 'components/common/UserConnectionsTooltip';
import useFlags from 'hooks/useFlags';
import { getCompanyUserConnectionsWithOverviewAndEvents } from 'queries/getCompanyUserConnections';
import { GET_CURRENT_USER } from 'queries/getCurrentUser';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortByConnectionRecencyAndCurrentlyWorkingAtCompany } from 'utils/company';
import { COMPANIES_ROUTE, DASHBOARD_ROUTE, SPLITS } from 'utils/constants';
import { getGroupedCompanyUserConnections } from 'utils/userConnections';
import CompanyContactEmailsPhone from '../CompanyContact/CompanyContactEmailsPhone';
import PersonConnectionCard from './PersonConnectionCard';
const PersonConnectionsLoading = () => {
    return (_jsxs("div", { "data-testid": "PersonConnectionsLoading", className: "mt-4 flex flex-col gap-4", children: [_jsx("div", { className: "h-64 w-full bg-slate-200 animate-pulse rounded-md" }), _jsx("div", { className: "h-64 w-full bg-slate-200 animate-pulse rounded-md" }), _jsx("div", { className: "h-64 w-full bg-slate-200 animate-pulse rounded-md" })] }));
};
const LoadMore = ({ onClick }) => {
    return (_jsx("button", { className: "text-content-headerGrayAlt text-base", onClick: onClick, children: "Load More" }));
};
const CompanyConnections = ({ companyId }) => {
    const enableCompanyEngagementTab = useFlags(SPLITS.enableCompanyEngagementTab);
    const navigate = useNavigate();
    if (!enableCompanyEngagementTab.enabled) {
        navigate(DASHBOARD_ROUTE + COMPANIES_ROUTE);
    }
    const [numVisibleConnections, setNumVisibleConnections] = useState(5);
    const { data, loading: userConnectionsLoading } = useQuery(getCompanyUserConnectionsWithOverviewAndEvents, {
        variables: { id: companyId },
        fetchPolicy: 'cache-first',
        skip: !enableCompanyEngagementTab.enabled
    });
    const { data: userData, loading: currentUserLoading } = useQuery(GET_CURRENT_USER, {
        fetchPolicy: 'cache-first'
    });
    const integrationStatus = userData?.getCurrentUser?.connectionsIntegrationStatus;
    const isLinkedinIntegrated = integrationStatus?.find((integ) => integ?.type === ConnectionIntegrationType.LINKEDIN)?.isEnabled ?? false;
    const isGmailIntegrated = integrationStatus?.find((integ) => integ?.type === ConnectionIntegrationType.GOOGLE)?.isEnabled ?? false;
    const isLoading = userConnectionsLoading || currentUserLoading;
    const companyUserConnections = data?.getCompanyById?.userConnections;
    const peopleWithConnections = useMemo(() => getGroupedCompanyUserConnections(companyUserConnections, companyId), [companyUserConnections, companyId]);
    const displayIntegrationflow = !isLinkedinIntegrated || !isGmailIntegrated;
    const userConnectionsExist = peopleWithConnections.length > 0;
    return (_jsxs("div", { className: "flex flex-col gap-g80 pb-p100 mb-p100", children: [!isLoading && (_jsx("div", { "data-testid": "ShowIntegrateButtonWithHeader", children: _jsx(ConnectionsBanner, { hideDismissButton: true }) })), _jsx("div", { className: "border-solid border-b border-border", children: _jsxs("p", { className: "py-p60 typography-title-medium text-content-title flex gap-g30", children: ["In network ", _jsx(UserConnectionsTooltip, {})] }) }), !isLoading && !userConnectionsExist ? (_jsx("p", { className: "typography-label-default-default text-int-fill-secondary-onDisabled mb-g80", children: _jsx("span", { "data-testid": "No-Connections-Exist-Text", children: "Not in team network." }) })) : !isLoading && userConnectionsExist ? (_jsxs("div", { className: "py-p50 flex flex-col gap-g50", children: [peopleWithConnections
                        .sort((a, b) => sortByConnectionRecencyAndCurrentlyWorkingAtCompany(a, b, companyId))
                        .slice(0, numVisibleConnections)
                        .map((personWithConnections) => {
                        return (_jsx(PersonConnectionCard, { personWithConnections: personWithConnections, currentCompanyId: companyId }, personWithConnections?.person?.id));
                    }), peopleWithConnections.length > numVisibleConnections && (_jsx(LoadMore, { onClick: () => setNumVisibleConnections((prev) => prev + 10) }))] })) : (_jsx(PersonConnectionsLoading, {})), _jsx(CompanyContactEmailsPhone, { companyId: companyId })] }));
};
export default CompanyConnections;
