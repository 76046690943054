import { gql } from '@apollo/client';
const upsertPeopleWatchlistNamedViewMutation = gql `
  mutation UpsertPeopleWatchlistNamedView(
    $watchlistUrn: PeopleWatchlistUrn!
    $namedViewUrn: PersonListNamedViewUrn
    $namedViewInput: PersonListNamedViewUpsertInput!
  ) {
    upsertPeopleListNamedView(
      watchlistUrn: $watchlistUrn
      namedViewUrn: $namedViewUrn
      namedViewInput: $namedViewInput
    ) {
      id
      entityUrn
      name
      visibleColumns
      searchQuery
      displayType
      groupByField {
        urn
      }
      hideEmptyColumns
    }
  }
`;
export default upsertPeopleWatchlistNamedViewMutation;
