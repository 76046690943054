import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeProvider } from '@material-ui/core';
import AppLayout from 'AppLayout';
import { logout, setUserApiKey, setUserRole, setUserStatus } from 'actions/authActions';
import { useMultipleFlags } from 'hooks/useFlags';
import { LogoutReason } from 'interfaces/Auth';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useIntercom } from 'react-use-intercom';
import { theme } from 'styles/materialUiTheme';
import analytics from 'utils/analytics';
import { IS_PROD_LIKE_ENV, SPLITS } from 'utils/constants';
import { logger } from 'utils/logger';
import { authenticate, getUserApiKey } from 'utils/midtierApi';
import { useAuthState } from './hooks/useAppState';
import { useMemoryUsage } from './hooks/useMemoryUsage';
import { useNetworkConnectionInfo } from './hooks/useNetworkConnectionInfo';
import './styles/App.css';
const App = () => {
    const featureFlags = useMultipleFlags(Object.values(SPLITS));
    const authState = useAuthState();
    const intercom = useIntercom();
    const networkConnectionInfo = useNetworkConnectionInfo();
    const memoryUsage = useMemoryUsage();
    const getIntercomUserArguments = (authState) => {
        const userMetadata = authState.userMetadata;
        let company = undefined;
        if (userMetadata) {
            company = {
                companyId: userMetadata.customer_urn,
                name: userMetadata.customer_name
            };
        }
        return {
            userId: authState.user?.user_id,
            name: authState.user?.displayName,
            email: authState.user?.email,
            company
        };
    };
    const shutdownIntercom = () => intercom.shutdown();
    const bootIntercom = () => {
        intercom.boot(getIntercomUserArguments(authState));
    };
    useEffect(() => {
        analytics.loadHeap();
    }, []);
    useEffect(() => {
        if (authState.user) {
            // Intercom is not supported in CI
            if (IS_PROD_LIKE_ENV && !process.env.CI) {
                bootIntercom();
            }
            intercom.update(getIntercomUserArguments(authState));
            // Required in case the user is paused during their session
            authenticate()
                .then((user) => {
                setUserStatus(user.status);
                setUserRole(user.role);
            })
                .catch((err) => {
                logout(LogoutReason.SessionExpired, err);
            });
        }
        if (authState.apiKey || !authState.user)
            return;
        getUserApiKey()
            .then((apiKey) => {
            setUserApiKey(apiKey);
        })
            .catch((err) => {
            if (IS_PROD_LIKE_ENV && !process.env.CI) {
                shutdownIntercom();
            }
            logout(LogoutReason.SessionExpired, err);
            logger.error(`Auth state changed error: ${err}`, {
                code_area: 'app',
                err
            });
        });
        // Above hook should be only called when authState.user changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.user]);
    useEffect(() => {
        logger.addGlobalContext({
            networkType: networkConnectionInfo?.type,
            networkDownlink: networkConnectionInfo?.downlink,
            networkDownlinkMax: networkConnectionInfo?.downlinkMax
        });
        // Heap only supports scalar values for event properties, so we need
        // to transform the list into a comma separated string.
        const enabledFlagNames = Object.entries(featureFlags)
            .filter(([, value]) => value.enabled)
            .map(([key]) => key);
        analytics.addEventProperties({ featureFlags: enabledFlagNames.join(',') });
        if (memoryUsage) {
            logger.addGlobalContext(memoryUsage);
        }
    }, [featureFlags, networkConnectionInfo, memoryUsage]);
    return (_jsx(ThemeProvider, { theme: theme, children: _jsx(BrowserRouter, { children: _jsx(Routes, { children: _jsx(Route, { path: "*", element: _jsx(AppLayout, {}) }) }) }) }));
};
export default App;
