import { jsx as _jsx } from "react/jsx-runtime";
import { getIdFromUrn } from 'common/utils/urn';
import SegmentedControl from 'harmonic-components/SegmentedControl/SegmentedControl';
import useDashboardLocation, { EntityType } from 'hooks/useDashboardLocation';
import { capitalize } from 'lodash';
import { useNavigate } from 'react-router-dom';
const InvestorViewToggle = (props) => {
    const { baseEntityUrn, investorUrn } = props;
    const { location: dashboardLocation } = useDashboardLocation();
    const navigate = useNavigate();
    const baseEntityType = baseEntityUrn.includes('company')
        ? EntityType.COMPANY
        : EntityType.PERSON;
    const currentView = dashboardLocation?.includes('investor')
        ? EntityType.INVESTOR
        : baseEntityType;
    const investorId = getIdFromUrn(investorUrn);
    const baseEntityId = getIdFromUrn(baseEntityUrn);
    return (_jsx("div", { "data-testid": "InvestorViewToggle", children: _jsx(SegmentedControl, { selectedOption: currentView, onChange: (newOption) => {
                if (newOption === EntityType.INVESTOR &&
                    currentView !== EntityType.INVESTOR) {
                    navigate(`/dashboard/investor/${investorId}`);
                }
                else if (newOption !== EntityType.INVESTOR &&
                    currentView === EntityType.INVESTOR) {
                    navigate(`/dashboard/${baseEntityType}/${baseEntityId}`);
                }
            }, options: [
                { label: capitalize(baseEntityType), value: baseEntityType },
                { label: 'Investor', value: EntityType.INVESTOR }
            ], variant: "compact" }) }));
};
export default InvestorViewToggle;
