import { EntityListModalType, EntityListType } from 'interfaces/SearchModel/Search';
import useCreatePeopleList from './Watchlists/useCreatePeopleList';
import { useCreateCompanyWatchlist } from './useCreateCompanyWatchlist';
import { useCreateSavedSearchList } from './useCreateSavedSearchList';
import { useUpsertEntityListModal } from './useUpsertEntityListModal';
export function useAddEntityListModal({ searchQuery, entityType, disableNavigation, onSuccess, disableToast }) {
    const { isUpsertEntityListModalOpen, showUpsertEntityListModal, closeUpsertEntityListModal } = useUpsertEntityListModal();
    const { createSavedSearchList } = useCreateSavedSearchList();
    const { createPeopleList } = useCreatePeopleList();
    const { createCompanyWatchlist } = useCreateCompanyWatchlist();
    const createEntityList = async (entityName) => {
        if (entityType === EntityListType.COMPANY_SAVED_SEARCH ||
            entityType === EntityListType.PEOPLE_SAVED_SEARCH) {
            await createSavedSearchList(entityName, entityType, searchQuery, disableNavigation);
        }
        if (entityType === EntityListType.COMPANY_WATCHLIST) {
            await createCompanyWatchlist(entityName, disableNavigation, disableToast, onSuccess);
        }
        if (entityType === EntityListType.PEOPLE_WATCHLIST) {
            await createPeopleList(entityName, disableNavigation, disableToast, onSuccess);
        }
        closeUpsertEntityListModal();
    };
    const showAddEntityListModal = () => {
        showUpsertEntityListModal({
            entityType,
            modalType: EntityListModalType.CreateList,
            onSubmit: createEntityList
        });
    };
    return {
        isAddEntityListModalOpen: isUpsertEntityListModalOpen,
        showAddEntityListModal
    };
}
