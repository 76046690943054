import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { mergeAppStore } from '../config/util';
import { INITIAL_COMPANY_SORT, INITIAL_SEARCH_MODEL } from '../interfaces/SearchModel/Search';
const useAuthStore = (set) => ({
    user: null,
    isAuthenticated: false,
    loading: false,
    error: '',
    needVerification: false,
    success: '',
    status: undefined,
    role: undefined,
    apiKey: '',
    setUser: (user) => set((state) => ({
        ...state,
        auth: {
            ...state.auth,
            user,
            isAuthenticated: !!user,
            loading: false
        }
    })),
    setUserMetadata: (metadata) => set((state) => ({
        ...state,
        auth: {
            ...state.auth,
            userMetadata: {
                ...state.auth.userMetadata,
                ...metadata
            }
        }
    })),
    setUserStatus: (status) => set((state) => ({
        ...state,
        auth: {
            ...state.auth,
            status
        }
    })),
    setUserRole: (role) => set((state) => ({
        ...state,
        auth: {
            ...state.auth,
            role
        }
    })),
    setLoading: (loading) => set((state) => ({
        ...state,
        auth: {
            ...state.auth,
            loading
        }
    })),
    setApiKey: (apiKey) => set((state) => ({
        ...state,
        auth: {
            ...state.auth,
            apiKey
        }
    })),
    logout: () => set((state) => ({
        ...state,
        auth: {
            ...state.auth,
            user: null,
            isAuthenticated: false,
            loading: false,
            apiKey: ''
        }
    })),
    setError: (error) => set((state) => ({
        ...state,
        auth: {
            ...state.auth,
            error
        }
    })),
    setNeedVerification: () => set((state) => ({
        ...state,
        auth: {
            ...state.auth,
            needVerification: true
        }
    })),
    setSuccess: (success) => set((state) => ({
        ...state,
        auth: {
            ...state.auth,
            success
        }
    }))
});
const initialDashboardState = {
    searchModel: INITIAL_SEARCH_MODEL,
    sortField: INITIAL_COMPANY_SORT.sortField,
    sortIsDescending: false
};
const useDashboardStore = (set) => ({
    ...initialDashboardState,
    updateSearchModel: (searchModel) => set((state) => ({
        ...state,
        dashboard: {
            ...state.dashboard,
            searchModel: {
                ...state.dashboard.searchModel,
                ...searchModel
            }
        }
    })),
    setSearchModel: (searchModel) => set((state) => ({
        ...state,
        dashboard: {
            ...state.dashboard,
            searchModel
        }
    })),
    setSortField: (sortField) => set((state) => ({
        ...state,
        dashboard: {
            ...state.dashboard,
            sortField
        }
    })),
    setSortIsDescending: (sortIsDescending) => set((state) => ({
        ...state,
        dashboard: {
            ...state.dashboard,
            sortIsDescending
        }
    })),
    resetSelectedRowIds: () => set((state) => ({
        ...state,
        dashboard: {
            ...state.dashboard,
            selectedRowIds: []
        }
    })),
    resetDashboard: () => {
        set((state) => ({
            ...state,
            dashboard: {
                ...state.dashboard,
                ...initialDashboardState
            }
        }));
    }
});
const initialListState = {
    sortField: 'updatedAt',
    sortIsDescending: true
};
const useListStore = (set) => ({
    ...initialListState,
    setSortField: (sortField) => set((state) => ({
        ...state,
        collection: {
            ...state.collection,
            sortField
        }
    })),
    setSortIsDescending: (sortIsDescending) => set((state) => ({
        ...state,
        collection: {
            ...state.collection,
            sortIsDescending
        }
    }))
});
const useLocalSearch = (set) => ({
    searchByKey: {},
    updateLocalSearch: (key, payload) => set((state) => ({
        ...state,
        localSearch: {
            ...state.localSearch,
            searchByKey: {
                ...state.localSearch.searchByKey,
                [key]: payload
            }
        }
    })),
    removeLocalSearch: (key) => {
        set((state) => ({
            ...state,
            localSearch: {
                ...state.localSearch,
                searchByKey: {
                    ...(state.localSearch.searchByKey || {}),
                    [key]: undefined
                }
            }
        }));
    }
});
export const useAppStore = create(persist((...args) => ({
    auth: useAuthStore(...args),
    dashboard: useDashboardStore(...args),
    localSearch: useLocalSearch(...args),
    collection: useListStore(...args)
}), {
    name: 'app-store',
    merge: mergeAppStore,
    version: 1.1
}));
