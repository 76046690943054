import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
export const theme = unstable_createMuiStrictModeTheme({
    palette: {
        primary: {
            main: '#5A5E61'
        },
        secondary: {
            main: '#5A5E61'
        }
    },
    typography: {
        fontFamily: ['"InterVariable"', 'Arial', 'sans-serif'].join(',')
    }
});
