import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import defaultCompanyLogo from 'assets/company-profile/default-logo.svg';
import defaultPersonLogo from 'assets/company-profile/default-person-logo.svg';
import classNames from 'classnames';
import { Badge, Tooltip } from 'common/components';
import { getIdFromUrn } from 'common/utils/urn';
import Avatar from 'harmonic-components/Avatar/Avatar';
import { useState } from 'react';
import { shouldLinkCompanyId } from 'utils/company';
import { COMPANY_ROUTE, DASHBOARD_ROUTE, INVESTOR_ROUTE, PERSON_ROUTE } from 'utils/constants';
import { getFundingStageBadgeColor } from 'utils/funding';
import { useOutsideClick } from 'utils/hooks';
import { isSafeHostedAsset } from 'utils/utilities';
import InternalLink from '../InternalLink';
import Paper from '../Paper';
const InvestorRoundMap = {
    'Series A': 'A',
    'Series B': 'B',
    'Series C': 'C',
    'Series D': 'D',
    'Series E': 'E',
    'Series F': 'F',
    'Series G': 'G',
    'Series H': 'H',
    'Series I': 'I',
    'Series J': 'J',
    'Series 1': '1',
    'Series A1': 'A1',
    'Series A2': 'A2',
    'Series 2': '2',
    'Series A3': 'A3',
    'Series C1': 'C1',
    'Series B2': 'B2',
    'Series AA': 'AA',
    'Series 3': '3',
    'Series D1': 'D1',
    'Series C2': 'C2',
    'Series B3': 'B3',
    'Series BB': 'BB',
    'Series E1': 'E1',
    'Series D2': 'D2',
    'Series C3': 'C3',
    'Series AAA': 'AAA',
    'Series CC': 'CC',
    'Series E2': 'E2',
    'Series D3': 'D3'
};
const InvestorRound = ({ round, differentColorForLead }) => {
    const mappedValue = InvestorRoundMap?.[round.name] ?? round.name;
    return (_jsx(Tooltip, { title: `${round.name} ${round.isLead ? '(Lead)' : ''}`, children: _jsx(Badge, { label: mappedValue, color: round.isLead && differentColorForLead
                ? 'expressive-2'
                : getFundingStageBadgeColor(round.name), intensity: "subtle", dataTestId: "InvestorRound-Chip" }) }));
};
const InvestorCard = ({ investorName, entityUrn = '', logoUrl, dataTestId, showLeadChip, currentRound = '', limit = 3, rounds = [] }) => {
    const [isMoreRoundsChipDropdownOpen, setIsMoreRoundsChipDropdownOpen] = useState(false);
    const id = getIdFromUrn(entityUrn);
    const isCompanyInvestor = entityUrn?.includes('urn:harmonic:company');
    const isPersonInvestor = entityUrn?.includes('urn:harmonic:person');
    const filteredRounds = rounds.filter((round) => round.name !== currentRound);
    const url = shouldLinkCompanyId(id)
        ? `${DASHBOARD_ROUTE}${isCompanyInvestor
            ? COMPANY_ROUTE
            : isPersonInvestor
                ? PERSON_ROUTE
                : INVESTOR_ROUTE}/${id}`
        : undefined;
    const toLink = url ? { pathname: url } : undefined;
    const defaultLogo = isCompanyInvestor
        ? defaultCompanyLogo
        : defaultPersonLogo;
    const logoExist = logoUrl && isSafeHostedAsset(logoUrl);
    const safeLogoUrl = logoExist ? logoUrl : defaultLogo;
    const roundsDropdownRef = useOutsideClick(() => setIsMoreRoundsChipDropdownOpen(false));
    const isGreaterThanLimit = filteredRounds.length > limit;
    return (_jsx(InternalLink, { "data-testid": "InvestorCard-Link", to: toLink, children: _jsx(Paper, { isInteractive: true, children: _jsxs("div", { "data-testid": dataTestId, className: "flex items-center justify-between gap-g50", children: [_jsxs("div", { className: "flex gap-g50 items-center", children: [_jsx(Avatar, { src: safeLogoUrl, alt: "company logo", kind: isCompanyInvestor ? 'logo' : 'face', dataTestId: "InvestorCard-Logo" }), _jsx("div", { className: "flex gap-g50", children: _jsx("p", { className: classNames('typography-label-default-strong text-content-default line-clamp-1'), children: investorName }) })] }), _jsxs("div", { className: "flex gap-g30", children: [showLeadChip && (_jsx(Badge, { label: "Lead", color: "expressive-32", intensity: "highlight" })), filteredRounds.length > 0 && (_jsxs(_Fragment, { children: [filteredRounds.slice(0, limit).map((round) => (_jsx(InvestorRound, { round: round }, round.name))), isGreaterThanLimit && (_jsxs("div", { className: "relative", children: [_jsxs("button", { onClick: (e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setIsMoreRoundsChipDropdownOpen(true);
                                                }, "data-testid": "MoreRounds-Toggle-Button", className: "text-content-headerGray text-content-core px-2 -ml-2", children: ["+", filteredRounds.length - limit] }), isMoreRoundsChipDropdownOpen && (_jsx("div", { ref: roundsDropdownRef, "data-testid": "MoreRounds-Dropdown", className: "absolute bg-white flex-nowrap p-2 min-w-20 border border-solid border-modal-mainStroke shadow-search rounded-md flex flex-col gap-1.5", children: filteredRounds.slice(limit).map((round) => (_jsx("div", { children: _jsx(InvestorRound, { differentColorForLead: false, round: round }) }, round.name))) }))] }))] }))] })] }) }) }, investorName));
};
export default InvestorCard;
