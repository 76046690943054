import { gql } from '@apollo/client';
const upsertCompanyWatchlistNamedViewMutation = gql `
  mutation UpsertCompanyWatchlistNamedView(
    $watchlistUrn: CompanyWatchlistUrn!
    $namedViewUrn: CompanyListNamedViewUrn
    $namedViewInput: CompanyListNamedViewUpsertInput!
  ) {
    upsertCompanyListNamedView(
      watchlistUrn: $watchlistUrn
      namedViewUrn: $namedViewUrn
      namedViewInput: $namedViewInput
    ) {
      id
      entityUrn
      name
      visibleColumns
      searchQuery
      displayType
      hideEmptyColumns
      groupByField {
        urn
      }
    }
  }
`;
export default upsertCompanyWatchlistNamedViewMutation;
