import { gql } from '@apollo/client';
import { FullCompanySearch } from './companySearchFragment';
export const getCompaniesByIds = gql `
  query GetCompaniesByIds(
    $ids: [Int!]!
    $extended: Boolean!
    $skipExtended: Boolean = false
    $skipPeople: Boolean = false
    $skipAffinityLists: Boolean = false
    $skipUserConnections: Boolean = false
    $skipTractionMetrics: Boolean = false
    $skipInvestors: Boolean = false
    $skipLists: Boolean = false
    $skipEntityActions: Boolean = false
  ) {
    getCompaniesByIds(ids: $ids, extended: $extended) {
      ...CompanySearch
    }
  }
  ${FullCompanySearch}
`;
