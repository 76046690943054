import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { SearchType } from '__generated__/graphql';
import { getIdFromUrn } from 'common/utils/urn';
import { GET_NET_NEW_SAVED_SEARCH_COUNT } from 'queries/getSavedSearch';
import { CLEAR_COMPANY_NET_NEW, CLEAR_NET_NEW_FOR_SEARCH, CLEAR_PERSON_NET_NEW, GET_NET_NEW_COUNTS } from 'queries/netNew';
import { useMemo } from 'react';
const updateCacheAfterClear = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
client, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
existingCache, savedSearchUrn, ids) => {
    // Update the results in the SavedSearch
    existingCache.modify({
        id: client.cache.identify({
            __typename: 'SavedSearch',
            id: getIdFromUrn(savedSearchUrn)
        }),
        fields: {
            results(current) {
                const currentEdges = current.edges;
                const updatedEdges = currentEdges.map((edge) => ids.includes(Number(edge.node.netNewId))
                    ? {
                        ...edge,
                        node: {
                            ...edge.node,
                            netNewId: null
                        }
                    }
                    : edge);
                return { ...current, edges: updatedEdges };
            }
        }
    });
    // Update the NetNewCount
    existingCache.modify({
        id: client.cache.identify({
            __typename: 'NetNewCount',
            savedSearch: {
                entityUrn: savedSearchUrn
            }
        }),
        fields: {
            count(current) {
                return current - ids.length;
            }
        },
        broadcast: false
    });
    const currentCachedQuery = client.readQuery({
        query: GET_NET_NEW_SAVED_SEARCH_COUNT,
        variables: {
            idOrUrn: getIdFromUrn(savedSearchUrn)
        }
    });
    const currentCount = currentCachedQuery?.getSavedSearch?.results?.totalCount;
    currentCount &&
        client.writeQuery({
            query: GET_NET_NEW_SAVED_SEARCH_COUNT,
            data: {
                getSavedSearch: {
                    __typename: 'SavedSearch',
                    id: getIdFromUrn(savedSearchUrn),
                    results: {
                        __typename: 'SavedSearchResultsConnection',
                        totalCount: currentCount - ids.length
                    }
                }
            },
            variables: {
                idOrUrn: getIdFromUrn(savedSearchUrn)
            }
        });
};
const useNetNewCounts = () => {
    const client = useApolloClient();
    const { data, loading: searchMapLoading } = useQuery(GET_NET_NEW_COUNTS);
    const [clearNetNewForSearch, { loading: clearSearchLoading }] = useMutation(CLEAR_NET_NEW_FOR_SEARCH);
    const [clearCompanyNetNew, { loading: companyLoading }] = useMutation(CLEAR_COMPANY_NET_NEW, {
        optimisticResponse: {
            clearCompanyNetNew: null
        }
    });
    const [clearPersonNetNew, { loading: personLoading }] = useMutation(CLEAR_PERSON_NET_NEW, {
        optimisticResponse: {
            clearPersonNetNew: null
        }
    });
    const onClearNetNewForSearch = (searchUrn) => {
        clearNetNewForSearch({
            variables: { idOrUrn: searchUrn },
            update: (existingCache) => {
                existingCache.modify({
                    id: client.cache.identify({
                        __typename: 'NetNewCount',
                        savedSearch: {
                            entityUrn: searchUrn
                        }
                    }),
                    fields: {
                        count() {
                            return 0;
                        }
                    },
                    broadcast: false
                });
            }
        });
    };
    const onClearNetNewByIds = ({ ids, savedSearchUrn, type }) => {
        if (!savedSearchUrn) {
            return;
        }
        const clearNetNew = type === SearchType.COMPANIES_LIST
            ? clearCompanyNetNew
            : clearPersonNetNew;
        clearNetNew({
            variables: { netNewIds: ids },
            update: (existingCache) => updateCacheAfterClear(client, existingCache, savedSearchUrn, ids)
        });
    };
    const searchMap = useMemo(() => {
        return new Map(data?.getNetNewCounts
            .filter((data) => data.count > 0)
            .map(({ count, savedSearch }) => [
            savedSearch.entityUrn,
            { count: count, ...savedSearch }
        ]));
    }, [data]);
    return {
        getNetNewCountForSearch: (searchUrn) => searchMap.get(searchUrn)?.count || 0,
        hasNetNew: (searchUrn) => {
            if (!searchUrn || searchMap.size == 0) {
                return false;
            }
            const count = searchMap.get(searchUrn)?.count || 0;
            return count > 0;
        },
        onClearNetNewForSearch,
        onClearNetNewByIds,
        loading: clearSearchLoading || companyLoading || personLoading,
        searchMapLoading,
        searchMap
    };
};
export default useNetNewCounts;
