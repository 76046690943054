import { useApolloClient, useMutation } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import { GET_CURRENT_USER } from 'queries/getCurrentUser';
import { REFRESH_PERSON_EMAILS } from 'queries/refreshPersonEmails';
import { displayToast } from 'utils/toasts';
const useRefreshPersonEmails = () => {
    const client = useApolloClient();
    const [refreshPersonEmailsMutation, { loading, error }] = useMutation(REFRESH_PERSON_EMAILS);
    const refreshPersonEmails = async (personUrn) => {
        try {
            const res = await refreshPersonEmailsMutation({
                variables: {
                    personUrn
                },
                onCompleted: (data) => {
                    // Update the Person contacts in the cache to reflect being unlocked
                    client.cache.modify({
                        id: client.cache.identify({
                            __typename: 'Person',
                            id: getIdFromUrn(personUrn)
                        }),
                        fields: {
                            contact(existing = {}) {
                                // Get the existing emails from the cache
                                const existingEmails = existing.emails || [];
                                // Get the new emails from the mutation response
                                const newEmails = data.refreshPersonEmails.emails.map((e) => e.email);
                                // Merge the existing emails and new emails
                                const mergedEmails = [
                                    ...new Set([...existingEmails, ...newEmails])
                                ];
                                return {
                                    ...existing,
                                    emails: mergedEmails
                                };
                            },
                            emailEnrichmentStatus(existing = {}) {
                                return {
                                    ...existing,
                                    enrichedAt: new Date().toISOString()
                                };
                            }
                        }
                    });
                },
                // Refetch user info to update credits
                refetchQueries: [
                    {
                        query: GET_CURRENT_USER
                    }
                ]
            });
            if (!res.data?.refreshPersonEmails.creditsUsed &&
                (!res.data?.refreshPersonEmails.emails ||
                    res.data?.refreshPersonEmails.emails.length == 0)) {
                displayToast({
                    primaryText: 'Could not find emails for this person. No credits were charged.',
                    mode: 'error'
                });
            }
            if (res.data?.refreshPersonEmails.creditsUsed) {
                displayToast({
                    primaryText: 'Successfully requested emails',
                    mode: 'success'
                });
            }
        }
        catch (e) {
            displayToast({
                primaryText: 'Failed to request emails',
                mode: 'error'
            });
        }
    };
    return {
        refreshPersonEmails,
        loading,
        error
    };
};
export default useRefreshPersonEmails;
