import { jsx as _jsx } from "react/jsx-runtime";
import { CrossIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { ToastContainer, Zoom } from 'react-toastify';
import styled from 'styled-components';
/**
 *  .toast-body > div:nth-child(2) is needed because the wrapper has no class name and is not targetable from the props.
 * We need the wrapper div to have a size or be flex so we can take up the entire width in the child DOM.
 */
// https://fkhadra.github.io/react-toastify/how-to-style/
const StyledToastContainer = styled(ToastContainer) `
  margin-top: 10px;
  .Toastify__toast-icon {
    margin-inline-end: 0px;
    padding: var(--spacing-p20);
    width: 28px;
  }
  .toast-body > div:nth-child(2) {
    display: flex;
    flex: 1 1 0%;
  }
`;
const Toast = () => {
    const CloseButton = ({ closeToast }) => {
        return (_jsx(IconButton, { type: "inverted", emphasis: "low", icon: CrossIcon, onClick: closeToast, size: "compact" }));
    };
    return (_jsx(StyledToastContainer, { stacked: true, transition: Zoom, closeOnClick: true, hideProgressBar: true, autoClose: 10000, toastClassName: () => classNames('bg-surface-inverted-elevated p-p50 rounded-br40 shadow-static-elevation-floating flex justify-between items-start gap-g40 my-p40 typography-paragraph-default-default'), bodyClassName: () => classNames('toast-body p-0 flex flex-1 gap-g40 items-center'), position: "bottom-left", closeButton: ({ closeToast }) => _jsx(CloseButton, { closeToast: closeToast }) }));
};
export default Toast;
