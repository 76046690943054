import gql from 'graphql-tag';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from './getPeopleWatchlists';
export const GET_PEOPLE_WATCHLIST = gql `
  query GetPeopleWatchlist($idOrUrn: String!) {
    getPeopleWatchlistByIdOrUrn(idOrUrn: $idOrUrn) {
      ...PeopleWatchlist
    }
  }
  ${GET_PEOPLE_WATCHLIST_FRAGMENT}
`;
export const GET_PEOPLE_WATCHLIST_MOCKED_RESPONSE = {
    getPeopleWatchlistByIdOrUrn: {
        creator: {
            entityUrn: 'urn:harmonic:user:2885',
            email: 'john@harmonic.ai',
            __typename: 'User'
        },
        id: '5ab230cc-f93a-4cfb-82f7-25bcf3fe6664',
        entityUrn: 'urn:harmonic:people_watchlist:5ab230cc-f93a-4cfb-82f7-25bcf3fe6664',
        name: 'People #1',
        sharedWithTeam: true,
        __typename: 'PeopleWatchlist',
        userWatchlistType: 'PEOPLE',
        customFields: [],
        namedViews: [
            {
                id: 16538,
                entityUrn: 'urn:harmonic:person_list_named_view:16538',
                name: 'All',
                visibleColumns: [
                    'urn:harmonic:person_field:person_name',
                    'urn:harmonic:person_field:person_position_title',
                    'urn:harmonic:person_field:person_position_company_id',
                    'urn:harmonic:person_field:person_linkedin_url',
                    'urn:harmonic:person_field:person_education_experience_school',
                    'urn:harmonic:person_field:person_highlights_category_list',
                    'urn:harmonic:person_field:person_customers_connections',
                    'urn:harmonic:person_field:person_customers_connections_last_email_person_email',
                    'urn:harmonic:person_field:person_customers_connections_last_email_at',
                    'urn:harmonic:person_field:person_customers_connections_last_meeting_person_email',
                    'urn:harmonic:person_field:person_customers_connections_last_meeting_at',
                    'urn:harmonic:person_field:person_emails',
                    'urn:harmonic:person_field:person_linkedin_headline',
                    'urn:harmonic:person_field:person_last_company_id',
                    'urn:harmonic:person_field:person_location'
                ],
                searchQuery: {
                    filterGroup: {
                        joinOperator: 'and',
                        filters: [],
                        filterGroups: [],
                        filterGroupGenerators: null
                    },
                    pagination: {
                        start: null,
                        pageSize: 25
                    },
                    sort: [
                        {
                            sortField: 'person_entry_created_at',
                            descending: true,
                            sortContextValues: null
                        }
                    ],
                    controlledFilterGroup: null,
                    mergeQueryForNestedFields: false
                },
                displayType: 'GRID',
                __typename: 'PersonListNamedView'
            }
        ]
    }
};
