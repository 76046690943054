export const useMemoryUsage = () => {
    if (performance.memory) {
        const memoryUsage = {
            jsHeapSizeLimit: performance.memory.jsHeapSizeLimit,
            totalJSHeapSize: performance.memory.totalJSHeapSize,
            usedJSHeapSize: performance.memory.usedJSHeapSize
        };
        return memoryUsage;
    }
};
