import { jsx as _jsx } from "react/jsx-runtime";
import CircleCheckFilledIcon from 'assets/harmonic-icons/circle-check-filled';
import { ReactComponent as LinkedInIcon } from 'assets/linkedin-blue.svg';
import Button from 'harmonic-components/Button/Button';
import { HARMONIC_LINKEDIN_SYNC_DOC } from 'utils/constants';
const IntegrateLinkedinButton = ({ isIntegrated, type }) => {
    if (type === 'primary')
        return (_jsx(Button, { leadingIcon: LinkedInIcon, trailingIcon: isIntegrated
                ? (props) => (_jsx(CircleCheckFilledIcon, { ...props, className: "text-content-sentiment-positive", applyCurrentColor: true }))
                : undefined, onClick: () => window.open(HARMONIC_LINKEDIN_SYNC_DOC, '_blank'), type: isIntegrated ? 'secondary' : 'primary', size: "compact", emphasis: "high", label: isIntegrated ? 'Connected' : 'Connect LinkedIn account', isDisabled: isIntegrated }));
    return (_jsx(Button, { leadingIcon: (props) => _jsx(LinkedInIcon, { ...props }), trailingIcon: isIntegrated
            ? (props) => (_jsx(CircleCheckFilledIcon, { ...props, applyCurrentColor: false }))
            : undefined, onClick: () => window.open(HARMONIC_LINKEDIN_SYNC_DOC, '_blank'), type: "secondary", size: "compact", emphasis: "high", label: isIntegrated ? 'Connected' : 'Connect LinkedIn account', isDisabled: isIntegrated }));
};
export default IntegrateLinkedinButton;
