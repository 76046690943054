import { toast } from 'react-toastify';
import { AuthErrorCodes, LogoutReason } from 'interfaces/Auth';
import { getIdFromUrn } from 'common/utils/urn';
import { getApp } from 'firebase/app';
import { getAuth, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import usePersistedZustandStore from 'stores/persistedZustandStore';
import { HARMONIC_CUSTOMER_URN, LOCAL_AGAINST_PROD, SESSION_EXPIRED_ERROR_MESSAGE } from 'utils/constants';
import { logger } from 'utils/logger';
import { authenticate } from 'utils/midtierApi';
import { useAppStore } from '../hooks/useAppStore';
export const setLoading = (value) => {
    useAppStore.getState().auth.setLoading(value);
};
const loginError = ({ error, onError }) => {
    logger.error(`Error login user: ${error && error.message}`, {
        error,
        code_area: 'login'
    });
    onError();
    if (error.code === AuthErrorCodes.USER_NOT_FOUND) {
        useAppStore
            .getState()
            .auth.setError('This email isn’t connected to an account. <a class="text-blue-dark focus:underline" href="/signup">Sign up?</a>');
    }
    if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
        useAppStore
            .getState()
            .auth.setError('The password is incorrect for this email address.');
    }
    useAppStore.getState().auth.setError(error.message);
};
export const logout = async (reason = LogoutReason.UserLoggedOut, error) => {
    if (!useAppStore.getState().auth.isAuthenticated) {
        return;
    }
    useAppStore.getState().auth.logout();
    useAppStore.getState().dashboard.resetDashboard();
    try {
        const auth = getAuth(getApp());
        await signOut(auth);
        switch (reason) {
            case LogoutReason.SessionExpired:
                toast.dark(SESSION_EXPIRED_ERROR_MESSAGE, {
                    autoClose: false
                });
                logger.error(`User session expired`, {
                    code_area: 'logout',
                    error
                });
                break;
            case LogoutReason.UserLoggedOut:
            default:
                toast.info('You have been successfully logged out.');
                usePersistedZustandStore.getState().reset();
                break;
        }
    }
    catch (error) {
        logger.error(`Error logging user out: ${error && error.message}`, {
            error,
            code_area: 'logout'
        });
    }
};
export const setError = (msg) => {
    useAppStore.getState().auth.setError(msg);
};
const setSuccess = (msg) => {
    useAppStore.getState().auth.setSuccess(msg);
};
export const setUserApiKey = (token) => {
    useAppStore.getState().auth.setApiKey(token);
};
export const setUserStatus = (status) => {
    useAppStore.getState().auth.setUserStatus(status);
};
export const setUserRole = (role) => {
    useAppStore.getState().auth.setUserRole(role);
};
export const sendPasswordResetEmailHandler = async (email, successMsg, onSuccess, onError) => {
    const auth = getAuth(getApp());
    useAppStore.getState().auth.setLoading(true);
    try {
        await sendPasswordResetEmail(auth, email);
        useAppStore.getState().auth.setSuccess(successMsg);
        setSuccess(successMsg);
        onSuccess();
    }
    catch (error) {
        onError();
        logger.error(`Error resetting user password: ${error && error.message}`, {
            error,
            code_area: 'reset_password'
        });
        useAppStore.getState().auth.setError(error.message);
    }
};
// ========= V2 AUTH ACTIONS =============
const signInToHarmonic = async () => {
    const authenticateResponse = await authenticate();
    const firebaseAuth = getAuth(getApp());
    const firebaseUser = firebaseAuth.currentUser;
    if (LOCAL_AGAINST_PROD) {
        if (authenticateResponse.customer !== HARMONIC_CUSTOMER_URN) {
            throw new Error('Can only login to Harmonic users in local environment.');
        }
    }
    if (firebaseUser && authenticateResponse.apikey) {
        useAppStore.getState().auth.setApiKey(authenticateResponse.apikey);
        useAppStore
            .getState()
            .auth.setUserStatus(authenticateResponse.status);
        useAppStore
            .getState()
            .auth.setUserRole(authenticateResponse.role);
        useAppStore.getState().auth.setUser({
            email: firebaseUser.email || '',
            email_verified: firebaseUser.emailVerified,
            user_id: firebaseUser.uid,
            displayName: authenticateResponse.name || firebaseUser.displayName || ''
        });
        useAppStore.getState().auth.setUserMetadata({
            user_id: getIdFromUrn(authenticateResponse.entity_urn) || '',
            user_urn: authenticateResponse.entity_urn,
            customer_urn: authenticateResponse.customer,
            customer_name: authenticateResponse.customer_name,
            settings: authenticateResponse.settings
        });
    }
    return { authenticateResponse };
};
export const setDisplayName = ({ user, displayName }) => {
    if (!user)
        return;
    useAppStore.getState().auth.setUser({
        ...user,
        displayName
    });
};
export const signin = async (data, onSuccess, onError) => {
    useAppStore.getState().auth.setError('');
    useAppStore.getState().auth.setLoading(true);
    const { email, password } = data;
    const auth = getAuth(getApp());
    try {
        const response = await signInWithEmailAndPassword(auth, email, password);
        if (!response?.user?.emailVerified) {
            sendEmailVerification(response.user);
            useAppStore
                .getState()
                .auth.setError('Email verification required. Check your inbox for a new link.');
            onError();
        }
    }
    catch (error) {
        const functionName = 'authActions/signin';
        loginError({
            functionName,
            error: error,
            onError
        });
        return;
    }
    const { authenticateResponse } = await signInToHarmonic();
    try {
        onSuccess({
            status: 'ok',
            apiKey: authenticateResponse.apikey
        });
    }
    catch (error) {
        onError(error);
    }
    useAppStore.getState().auth.setLoading(false);
};
export const loginWithSSO = async (response, onSuccess, onError) => {
    useAppStore.getState().auth.setError('');
    useAppStore.getState().auth.setLoading(true);
    if (!response?.user?.emailVerified) {
        sendEmailVerification(response.user);
        useAppStore
            .getState()
            .auth.setError('Email verification required. Check your inbox for a new link.');
        onError();
    }
    try {
        const { authenticateResponse } = await signInToHarmonic();
        onSuccess({
            status: 'ok',
            apiKey: authenticateResponse.apikey
        });
    }
    catch (error) {
        onError(error);
    }
    useAppStore.getState().auth.setLoading(false);
};
export const setOnboardingSuccess = (successState = true) => {
    const existingUserMetadata = useAppStore.getState().auth.userMetadata;
    if (existingUserMetadata) {
        useAppStore.getState().auth.setUserMetadata({
            ...existingUserMetadata,
            settings: {
                ...existingUserMetadata?.settings,
                has_completed_onboarding: successState
            }
        });
    }
};
