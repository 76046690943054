import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import InternalLink from 'components/common/InternalLink';
import { get, uniqBy } from 'lodash';
import { shouldLinkCompanyId } from 'utils/company';
import { EventLocations } from './analytics';
import { HighlightCategoryMap } from './constants';
export const getCompanyHighlights = (value, company) => {
    const list = [];
    // Primary Highlights
    uniqBy(value, 'text')?.forEach((highlight) => {
        if (highlight.text && !highlight.category.includes('_')) {
            const splittedHighlightText = highlight?.text?.split(':');
            const tooltip = shouldLinkCompanyId(company?.id) ? (_jsxs("div", { children: [_jsxs(InternalLink, { className: "underline", to: { pathname: `/dashboard/company/${company?.id}` }, openedFromLocation: EventLocations.GRID_TABLE_HIGHLIGHTS, children: [splittedHighlightText?.[0], ":"] }), splittedHighlightText?.[1]] })) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            _jsx(_Fragment, { children: highlight.text }));
            list.push({
                category: highlight.category,
                count: 1,
                tooltip
            });
        }
    });
    // company's secondary highlights are employee highlights
    const secondaryHighlights = get(company, 'employee_highlights', []);
    const formattedSecondaryHighlights = secondaryHighlights?.map((highlight) => {
        return {
            category: HighlightCategoryMap[highlight.category] || highlight.category,
            text: highlight.text
        };
    });
    const groupedHighlights = new Map();
    if (formattedSecondaryHighlights) {
        formattedSecondaryHighlights
            ?.filter((highlight) => highlight && !highlight.category?.includes('_'))
            .forEach((highlight) => {
            if (highlight?.category) {
                groupedHighlights.set(highlight?.category, (groupedHighlights.get(highlight?.category) || []).concat([
                    highlight
                ]));
            }
        });
    }
    // Aggregate primary and secondary highlights
    groupedHighlights.forEach((groupedHighlights, category) => {
        const companyId = company?.id;
        groupedHighlights = uniqBy(groupedHighlights, 'text');
        const tooltip = (_jsx("div", { className: "flex flex-col w-full gap-g30", children: groupedHighlights.map((highlight, index) => {
                return (_jsx("div", { className: "w-full", children: _jsx(_Fragment, { children: highlight.text }) }, `${companyId}-highlightLink-${index}`));
            }) }));
        list.push({
            category,
            count: groupedHighlights.length,
            tooltip
        });
    });
    return list;
};
export const getPeopleHighlights = (value) => {
    const list = [];
    const groupedHighlights = new Map();
    if (value) {
        value
            .filter((highlight) => highlight && !highlight.category?.includes('_'))
            .forEach((highlight) => groupedHighlights.set(highlight?.category, (groupedHighlights.get(highlight?.category) || []).concat([highlight])));
    }
    groupedHighlights.forEach((groupedHighlights, category) => {
        list.push({
            category,
            count: groupedHighlights.length
        });
    });
    return list;
};
