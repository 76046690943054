import { useAppStore } from './useAppStore';
export const useAppState = (selector) => {
    return useAppStore(selector);
};
export const useAuthState = () => {
    const authState = useAppState((state) => state.auth);
    return {
        apiKey: authState.apiKey,
        error: authState.error,
        success: authState.success,
        status: authState.status,
        needVerification: authState.needVerification,
        user: authState.user,
        isAuthenticated: authState.isAuthenticated,
        userMetadata: authState.userMetadata,
        role: authState.role,
        loading: authState.loading
    };
};
export const useDashboardState = () => {
    const dashboardState = useAppState((state) => state.dashboard);
    return dashboardState;
};
export const useLocalSearchState = () => {
    const localSearchState = useAppState((state) => state.localSearch);
    return localSearchState;
};
