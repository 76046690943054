import { useApolloClient } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import useDashboardLocation, { DashboardLocation } from 'hooks/useDashboardLocation';
import useFetchSingleSavedSearch from 'hooks/useFetchSingleSavedSearch';
import { EntityListType, INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { useShallowFilterStore } from 'stores/filterStore';
import useStore from 'stores/zustandStore';
import { updateSavedSearch } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
export const useCompanySearchSort = () => {
    const { urn, location: dashboardLocation } = useDashboardLocation();
    const userUrn = useStore((state) => state.userUrn);
    const { editFilterStore, savedSearchPageSort, companyExplorePageSort } = useShallowFilterStore([
        'editFilterStore',
        'savedSearchPageSort',
        'companyExplorePageSort'
    ]);
    const sort = useMemo(() => {
        if (!urn && dashboardLocation === DashboardLocation.COMPANY_SEARCH) {
            return companyExplorePageSort;
        }
        return savedSearchPageSort;
    }, [companyExplorePageSort, dashboardLocation, savedSearchPageSort, urn]);
    const { getCurrentPersistedQuery, currentPageSavedSearch } = useFetchSingleSavedSearch();
    const isSearchOwner = currentPageSavedSearch?.creator?.entityUrn === userUrn;
    const entityId = urn && getIdFromUrn(urn);
    const client = useApolloClient();
    const isSavedSearch = dashboardLocation === DashboardLocation.COMPANY_SEARCH && entityId;
    const currentPersistedQuery = isSavedSearch &&
        entityId &&
        getCurrentPersistedQuery(EntityListType.COMPANY_SAVED_SEARCH, parseInt(entityId));
    const zustandStoreSortKey = useMemo(() => {
        if (!urn && dashboardLocation === DashboardLocation.COMPANY_SEARCH) {
            return 'companyExplorePageSort';
        }
        return 'savedSearchPageSort';
    }, [dashboardLocation, urn]);
    const updateSortDataOnBackend = async (sortField, isDescending) => {
        if (!isSavedSearch)
            return;
        const clonedSearchQuery = cloneDeep(currentPersistedQuery);
        const queryInfo = {
            filter_group: clonedSearchQuery.filter_group,
            controlled_filter_group: clonedSearchQuery.controlled_filter_group,
            sort: [
                {
                    sort_field: sortField,
                    descending: isDescending
                }
            ]
        };
        const mainPayload = {
            query: {
                ...INITIAL_SEARCH_MODEL,
                ...queryInfo
            }
        };
        if (!isSearchOwner)
            return;
        try {
            await updateSavedSearch(client, entityId ?? '', mainPayload);
            displayToast({
                primaryText: 'Successfully updated saved search',
                mode: 'success'
            });
        }
        catch {
            displayToast({
                primaryText: 'There was a problem updating the saved search',
                mode: 'error'
            });
        }
    };
    const changeSort = ({ field, descending }) => {
        editFilterStore(zustandStoreSortKey, {
            sortField: field,
            descending: descending
        });
        updateSortDataOnBackend(field, descending);
    };
    return {
        changeSort,
        sort
    };
};
