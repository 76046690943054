import { jsx as _jsx } from "react/jsx-runtime";
import { useApolloClient, useMutation } from '@apollo/client';
import { getUrnFragment } from 'common/utils/urn';
import ConfirmRemoveFromListModal from 'components/common/EntityActions/ConfirmRemoveFromListModal';
import { EntityListType, EntityURNTypeToDashboardPath } from 'interfaces/SearchModel/Search';
import { compact } from 'lodash';
import { ADD_COMPANIES_TO_WATCHLIST } from 'queries/addCompaniesFromWatchlist';
import { ADD_COMPANIES_TO_WATCHLIST_BY_CANONICALS } from 'queries/addCompaniesFromWatchlistByCanonicals';
import { GET_USER_PEOPLE_IMPORTS_BY_PEOPLE_LIST } from 'queries/getUserImportsByPeopleList';
import { GET_WATCHLIST, GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS } from 'queries/getWatchlist';
import { getWatchlistsFromCompanies } from 'queries/getWatchlistsFromCompanies';
import { REMOVE_COMPANIES_FROM_WATCHLIST } from 'queries/removeCompaniesFromWatchlist';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useShallowTableStore } from 'stores/tableStore';
import analytics, { CompanyActionEventType, CustomTrackEvent } from 'utils/analytics';
import { SPLITS } from 'utils/constants';
import { logger } from 'utils/logger';
import { displayToast } from 'utils/toasts';
import { getCustomFieldValue } from '../components/common/Board/utils';
import { WATCHLIST_WITH_COMPANIES } from '../queries/getWatchlistWithCompanies';
import { GET_WATCHLIST_FRAGMENT } from '../queries/getWatchlists';
import { UPSERT_COMPANY_WATCHLIST_ENTRIES } from '../queries/upsertCompanyWatchlistEntries';
import { useCompanyEntityActions } from './useCompanyEntityActions';
import useDashboardLocation from './useDashboardLocation';
import useFetchWatchlists from './useFetchWatchlists';
import useFlags from './useFlags';
import { useModal } from './useModal';
import { useRefetchCompanyWatchlistResults } from './useRefetchCompanyWatchlistResults';
const defaultOptions = {
    disableToast: false
};
export const useWatchlistActions = ({ disableToast } = defaultOptions) => {
    const { watchlists } = useFetchWatchlists();
    const { refetchResults } = useRefetchCompanyWatchlistResults();
    const { editTableStoreData } = useShallowTableStore(['editTableStoreData']);
    const client = useApolloClient();
    const { urn, location } = useDashboardLocation();
    const currentPageEntityId = getUrnFragment(urn, 3);
    const isSavedSearch = urn?.includes('urn:harmonic:saved_search');
    const { show: showConfirmationModal, close: closeConfirmationModal } = useModal();
    const { enabled: enableConfirmBeforeListRemoval } = useFlags(SPLITS.confirmOnDeleteFromList);
    const { setViewed, areViewed } = useCompanyEntityActions();
    const [addCompaniesToWatchlistMutation] = useMutation(ADD_COMPANIES_TO_WATCHLIST);
    const [addCompaniesToWatchlistByCanonicalsMutation] = useMutation(ADD_COMPANIES_TO_WATCHLIST_BY_CANONICALS);
    const [removeCompaniesFromWatchlistMutation] = useMutation(REMOVE_COMPANIES_FROM_WATCHLIST);
    const [upsertCompanyWatchlistEntry] = useMutation(UPSERT_COMPANY_WATCHLIST_ENTRIES);
    const navigate = useNavigate();
    const addCompaniesToWatchlist = async ({ watchlistId, shouldNavigate = false, companyIds }) => {
        const areCompaniesViewed = areViewed(companyIds);
        if (!areCompaniesViewed) {
            setViewed(companyIds);
        }
        await _addWatchlist(watchlistId, companyIds, false);
        _deselectRows();
        if (shouldNavigate) {
            const toNavigateRoute = `/dashboard/watchlist/urn:harmonic:${EntityURNTypeToDashboardPath[EntityListType.COMPANY_WATCHLIST]}:${watchlistId}`;
            navigate(toNavigateRoute);
        }
    };
    const addCompaniesToWatchlistByCanonicals = async (watchlistUrn, companyCanonicals, fileName, flatfileBatchId) => {
        const { data } = await addCompaniesToWatchlistByCanonicalsMutation({
            variables: {
                urn: watchlistUrn,
                canonicals: companyCanonicals,
                fileName,
                flatfileBatchId
            },
            refetchQueries: [
                {
                    query: GET_USER_PEOPLE_IMPORTS_BY_PEOPLE_LIST,
                    variables: {
                        companiesListIdOrUrn: watchlistUrn,
                        page: 0,
                        size: 10
                    }
                }
            ]
        });
        refetchResults(watchlistUrn);
        return data?.addCompaniesToWatchlistWithCanonicals;
    };
    const deleteCompaniesFromCurrentList = async (companyIds, fromHotkey = false) => {
        if (isSavedSearch) {
            return;
        }
        const watchlistId = `${currentPageEntityId}`;
        const currentWatchlist = watchlists.find((list) => list?.id === watchlistId);
        if (!currentWatchlist)
            return;
        removeCompaniesFromWatchlist({ watchlistId, companyIds, fromHotkey });
    };
    const removeCompaniesFromWatchlist = async ({ watchlistId, companyIds, fromHotkey, confirmBeforeRemoval, onConfirmRemoval }) => {
        if (enableConfirmBeforeListRemoval && confirmBeforeRemoval) {
            return new Promise((resolve) => {
                showConfirmationModal(_jsx(ConfirmRemoveFromListModal, { onConfirmRemoval: async () => {
                        onConfirmRemoval?.();
                        closeConfirmationModal();
                        await _removeWatchlist(watchlistId, companyIds, fromHotkey).then();
                        _deselectRows();
                        resolve();
                    }, listType: EntityListType.COMPANY_WATCHLIST, listId: watchlistId, entityIds: companyIds }), {
                    onClose: resolve
                });
            });
        }
        else {
            await _removeWatchlist(watchlistId, companyIds, fromHotkey);
            _deselectRows();
        }
    };
    const _removeWatchlist = async (key, companyIds, fromHotkey = false) => {
        const watchlistId = key;
        const isBulkAction = companyIds?.length > 1;
        analytics.trackCustomEvent({
            event: CustomTrackEvent.COMPANY_ACTION_CLICK,
            properties: {
                action: CompanyActionEventType.REMOVE_FROM_WATCHLIST,
                companyIds: companyIds.join(','),
                openedFromLocation: location,
                watchlistId,
                isBulkAction,
                fromHotkey
            }
        });
        const companyCountText = companyIds?.length === 1 ? 'company' : `${companyIds?.length} companies`;
        let watchlist;
        try {
            watchlist = client.readFragment({
                id: `CompanyWatchlist:${watchlistId}`,
                fragment: GET_WATCHLIST_FRAGMENT,
                fragmentName: 'Watchlist'
            });
        }
        catch (err) {
            logger.error(`CompanyWatchlist:${watchlistId} not found in cache`, {
                err,
                code_area: 'grid'
            });
        }
        await _optimisticallyRemoveCompaniesFromWatchlist(key, companyIds);
        if (!disableToast) {
            displayToast({
                primaryText: isBulkAction
                    ? `Removed ${companyCountText} from list${watchlist ? ` ${watchlist?.name}` : ''}`
                    : `Removed company from list${watchlist ? ` ${watchlist?.name}` : ''}`,
                link: `/dashboard/watchlist/urn:harmonic:company_watchlist:${watchlistId}`
            });
        }
    };
    const _addWatchlist = async (key, companyIds, fromHotkey = false) => {
        const watchlistId = key;
        const isBulkAction = companyIds?.length > 1;
        analytics.trackCustomEvent({
            event: CustomTrackEvent.COMPANY_ACTION_CLICK,
            properties: {
                action: CompanyActionEventType.ADD_TO_WATCHLIST,
                companyIds: companyIds.join(','),
                openedFromLocation: location,
                watchlistId,
                isBulkAction,
                fromHotkey
            }
        });
        const companyCountText = companyIds?.length === 1 ? 'company' : `${companyIds?.length} companies`;
        let watchlist;
        try {
            watchlist = client.readFragment({
                id: `CompanyWatchlist:${watchlistId}`,
                fragment: GET_WATCHLIST_FRAGMENT,
                fragmentName: 'Watchlist'
            });
        }
        catch (err) {
            logger.error(`CompanyWatchlist:${watchlistId} not found in cache`, {
                err,
                code_area: 'grid'
            });
        }
        await _optimisticallyAddCompaniesToWatchlist(key, companyIds);
        if (!disableToast) {
            displayToast({
                primaryText: isBulkAction
                    ? `Added ${companyCountText} to list${watchlist ? ` ${watchlist?.name}` : ''}`
                    : `Added company to list${watchlist ? ` ${watchlist?.name}` : ''}`,
                secondaryText: 'Go to list',
                link: `/dashboard/watchlist/urn:harmonic:company_watchlist:${watchlistId}`
            });
        }
    };
    const _deselectRows = () => {
        editTableStoreData('selectedRowIds', []);
    };
    const _optimisticallyRemoveCompaniesFromWatchlist = async (watchlistId, companyIds) => {
        const currentWatchlistFromCacheWithCompanyIds = client.readFragment({
            id: `CompanyWatchlist:${watchlistId}`, // The value of the watchlist in the cache
            fragment: GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS,
            fragmentName: 'WatchlistWithCompanyId'
        });
        return await removeCompaniesFromWatchlistMutation({
            variables: {
                id: watchlistId,
                companies: companyIds.map(String)
            },
            ...(currentWatchlistFromCacheWithCompanyIds && {
                optimisticResponse: {
                    removeCompaniesFromWatchlistWithIds: {
                        ...currentWatchlistFromCacheWithCompanyIds,
                        __typename: 'CompanyWatchlist',
                        companyIds: compact(currentWatchlistFromCacheWithCompanyIds?.companyIds?.filter((companyId) => !companyIds.includes(parseInt(companyId ?? ''))))
                    }
                }
            }),
            update: (existingCache) => {
                companyIds.forEach((companyId) => {
                    // Direct cache modify
                    existingCache.modify({
                        id: existingCache.identify({
                            __typename: 'Company',
                            id: companyId
                        }), // The id of the company in the cache.
                        fields: {
                            watchlists: (existingWatchlists, { readField }) => {
                                const newWatchlists = existingWatchlists.filter((watchlist) => readField('id', watchlist) !== watchlistId);
                                return newWatchlists;
                            }
                        },
                        optimistic: true,
                        broadcast: false
                    });
                });
                _clearCompaniesFromWatchlistCompaniesCache(companyIds, watchlistId);
            }
        });
    };
    // WARNING: The following adds the company to the top level watchlist element in the cache
    // this cache element powers the watchlist sidebar and only keeps a list of the company ids
    // BUT this is not the cache element that powers the watchlist search page.
    const _optimisticallyAddCompaniesToWatchlist = async (watchlistId, companyIds) => {
        const currentWatchlistFromCacheWithCompanyIds = client.readFragment({
            id: `CompanyWatchlist:${watchlistId}`, // The value of the watchlist item's cache ID
            fragment: GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS,
            fragmentName: 'WatchlistWithCompanyId'
        });
        const companyCountText = companyIds?.length === 1 ? 'company' : `${companyIds?.length} companies`;
        const toastId = toast(`${`Adding ${companyCountText} to list`}.`, {
            autoClose: false,
            closeOnClick: false,
            isLoading: true,
            closeButton: false
        });
        await addCompaniesToWatchlistMutation({
            variables: {
                id: watchlistId,
                companies: companyIds.map(String)
            },
            ...(currentWatchlistFromCacheWithCompanyIds && {
                optimisticResponse: {
                    addCompaniesToWatchlistWithIds: {
                        ...currentWatchlistFromCacheWithCompanyIds,
                        __typename: 'CompanyWatchlist',
                        id: watchlistId,
                        companyIds: (currentWatchlistFromCacheWithCompanyIds.companyIds ?? []).concat(companyIds.map(String))
                    }
                }
            }),
            update: (existingCache) => {
                companyIds.forEach((companyId) => {
                    // Direct cache modify
                    existingCache.modify({
                        id: existingCache.identify({
                            __typename: 'Company',
                            id: companyId
                        }), // The id of the company in the cache.
                        fields: {
                            watchlists: (existingWatchlists) => {
                                return [
                                    ...existingWatchlists,
                                    // Add the reference to the watchlist in the cache
                                    {
                                        __ref: existingCache.identify({
                                            __typename: 'CompanyWatchlist',
                                            id: watchlistId
                                        })
                                    }
                                ];
                            }
                        },
                        optimistic: true,
                        broadcast: false
                    });
                });
            }
        });
        refetchResults(watchlistId);
        toast.dismiss(toastId);
    };
    // This function removes companies from full WatchlistWithCompanies search element
    const _clearCompaniesFromWatchlistCompaniesCache = (companyIds, watchlistId) => {
        client.cache.modify({
            id: `CompanyWatchlist:${watchlistId}`,
            fields: {
                companyEntries: (cachedCompanyEntries, { readField }) => {
                    const edges = readField('edges', cachedCompanyEntries) || [];
                    const totalCount = readField('totalCount', cachedCompanyEntries) || 0;
                    const newEdges = edges.filter((edge) => {
                        const companyId = readField('id', readField('company', readField('node', edge)));
                        if (companyId === undefined)
                            return false;
                        return !companyIds.includes(companyId);
                    });
                    const netEdgesRemoved = edges.length - newEdges.length;
                    const updatedTotalCount = totalCount - netEdgesRemoved;
                    return {
                        ...cachedCompanyEntries,
                        totalCount: updatedTotalCount,
                        edges: newEdges
                    };
                }
            },
            broadcast: true,
            optimistic: true
        });
    };
    const getWatchlistContainingCompanies = (watchlists, companyIds) => {
        const newWatchlistSelections = [];
        watchlists.map((watchlist) => {
            if (isEntityOnWatchlist(watchlist, companyIds)) {
                newWatchlistSelections.push(watchlist?.id ?? '');
            }
        });
        return newWatchlistSelections;
    };
    const isEntityOnWatchlist = (watchlist, companyIds) => {
        const companyResult = client.readQuery({
            query: getWatchlistsFromCompanies,
            variables: { ids: companyIds, extended: false }
        });
        if (companyResult?.getCompaniesByIds?.length === 0)
            return false;
        const allCompaniesInWatchlist = companyResult?.getCompaniesByIds?.every((company) => company?.watchlists?.some((innerWatchlist) => innerWatchlist?.id === watchlist?.id)) || false;
        return allCompaniesInWatchlist;
    };
    const addCompanyToWatchlistWithCustomFieldValue = async ({ watchlistId, companyId, fieldUrn, option, variables }) => {
        const toastId = toast(`Adding company to ${option.name}...`, {
            autoClose: false,
            closeOnClick: false,
            isLoading: true,
            closeButton: false
        });
        await upsertCompanyWatchlistEntry({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_WATCHLIST,
                    variables: {
                        idOrUrn: `urn:harmonic:company_watchlist:${watchlistId}`
                    }
                },
                {
                    query: WATCHLIST_WITH_COMPANIES,
                    variables
                }
            ],
            variables: {
                watchlistUrn: `urn:harmonic:company_watchlist:${watchlistId}`,
                upsertEntriesInput: {
                    companyUrn: `urn:harmonic:company:${companyId}`,
                    customFieldValues: [
                        {
                            customFieldUrn: fieldUrn,
                            customFieldValueInput: {
                                selectData: {
                                    value: getCustomFieldValue(option.urn)
                                }
                            }
                        }
                    ]
                }
            }
        });
        toast.dismiss(toastId);
    };
    return {
        getWatchlistContainingCompanies,
        deleteCompaniesFromCurrentList,
        addCompaniesToWatchlist,
        removeCompaniesFromWatchlist,
        addCompaniesToWatchlistByCanonicals,
        addCompanyToWatchlistWithCustomFieldValue
    };
};
