import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useShallowTableStore } from '../stores/tableStore';
import { GRID_INITIAL_PAGE_SIZE } from '../utils/constants';
export const useRefetchWatchlistResults = ({ query, extendedQuery, variables, getDataPath, getIdsFromData, getUrnsFromExtendedData }) => {
    const client = useApolloClient();
    const { addToLoadedExtendedUrns } = useShallowTableStore([
        'addToLoadedExtendedUrns'
    ]);
    const [fetchCachedData] = useLazyQuery(query, {
        fetchPolicy: 'cache-only'
    });
    const [fetch] = useLazyQuery(query, {
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only'
    });
    const handleCompleted = (data) => {
        const entries = getDataPath(data);
        const ids = getIdsFromData(data);
        client
            .query({
            query: extendedQuery,
            variables: {
                ids,
                extended: true,
                skipExtended: false
            },
            fetchPolicy: 'network-only'
        })
            .then(({ data }) => {
            const urns = getUrnsFromExtendedData(data);
            addToLoadedExtendedUrns(urns);
        });
        return entries;
    };
    const refetchResults = async (idOrUrn) => {
        let cursor = null;
        const { data: cachedData } = await fetchCachedData({
            variables: {
                ...variables,
                idOrUrn
            }
        });
        const numCachedEdges = getDataPath(cachedData)?.edges?.length || 0;
        let numPages = Math.floor(numCachedEdges / GRID_INITIAL_PAGE_SIZE) + 1;
        while (numPages > 0) {
            const { data, error } = await fetch({
                variables: {
                    ...variables,
                    idOrUrn,
                    first: GRID_INITIAL_PAGE_SIZE,
                    after: cursor,
                    skipExtended: true
                }
            });
            if (!data || error)
                break;
            const entries = handleCompleted(data);
            const pageInfo = entries?.pageInfo;
            if (!pageInfo?.hasNextPage)
                break;
            cursor = pageInfo.endCursor ?? null;
            numPages--;
        }
    };
    return {
        refetchResults
    };
};
