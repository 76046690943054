import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Avatar as OldAvatar, TextField, makeStyles } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import SecurityIcon from '@material-ui/icons/Security';
import { Autocomplete } from '@material-ui/lab';
import { SearchType } from '__generated__/graphql';
import { AffinityIcon } from 'assets/harmonic-icons';
import CalendarDaysIcon from 'assets/harmonic-icons/calendar-days';
import classNames from 'classnames';
import { Badge, Tooltip } from 'common/components';
import { getIdFromUrn } from 'common/utils/urn';
import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import { CompanyNavbarOptions } from 'components/Dashboard/CompanyV2/CompanyHeader/CompanyHeader';
import PersonEmailUnlockModal from 'components/common/ContactData/PersonEmailUnlockModal';
import { getHighlightIcon } from 'components/common/Highlights/HighlightsChip';
import InternalLink from 'components/common/InternalLink';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import CompanyAttribute from 'harmonic-components/CompanyAttribute/CompanyAttribute';
import Email from 'harmonic-components/Email/Email';
import LinkAttribute from 'harmonic-components/LinkAttribute/LinkAttribute';
import PersonAttribute from 'harmonic-components/PersonAttribute/PersonAttribute';
import Tag from 'harmonic-components/Tag/Tag';
import TruncatedList from 'harmonic-components/TruncatedList/TruncatedList';
import useDashboardLocation, { DashboardLocation } from 'hooks/useDashboardLocation';
import useFlags from 'hooks/useFlags';
import { useModal } from 'hooks/useModal';
import { EventTypeToDescriptionMapping } from 'interfaces/DataModel/Event';
import { PersonExperienceDataTimeTypes, PersonExperienceDataTypes } from 'interfaces/DataModel/Person';
import { DerivedMetricType } from 'interfaces/Grid';
import { SearchValueToDisplayNameMap } from 'interfaces/SearchModel/Search';
import { compact, defaultTo, get, isArray, toInteger, toNumber, uniq } from 'lodash';
import { GET_ALL_TEAM_MEMBERS_WITH_INTEGRATED_EMAILS } from 'queries/getAllTeamMembersWithIntegratedEmails';
import { getCompaniesViewRecords } from 'queries/getCompanyViewRecords';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import * as React from 'react';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useTableStore, { useShallowTableStore } from 'stores/tableStore';
import useStore from 'stores/zustandStore';
import styled from 'styled-components';
import analytics, { CustomTrackEvent, EventLocations } from 'utils/analytics';
import { shouldLinkCompanyId } from 'utils/company';
import { DASHBOARD_ROUTE, PEOPLE_WATCHLIST_ROUTE, SPLITS, WATCHLIST_ROUTE } from 'utils/constants';
import { stringToExpressiveNumber } from 'utils/design';
import { addCommasToNumber, isSafeHostedAsset, truncateMoneyValue } from 'utils/utilities';
import { dayjsExt } from '../../../../config/dayjs';
import { useAuthState } from '../../../../hooks/useAppState';
import { getPeopleHighlights } from '../../../../utils/cellRendererUtils';
import BlankCellRenderer from './BlankCellRenderer';
import CompanyEmployeesExperienceCellRenderer from './CompanyEmployeesExperienceCellRenderer';
import { CompanyHighlights } from './components/CompanyHighlights';
import { DateCell } from './components/DateCell';
import { LongText } from './components/LongText';
import { PeopleList } from './components/PeopleList';
import { RelevanceScore } from './components/RelevanceScore';
import { Tags } from './components/Tags';
import UnviewedNewDot from './components/UnviewedNewDot';
import { UserConnections } from './components/UserConnections';
export const useStyles = makeStyles((theme) => ({
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    'internal-link': {
        cursor: 'pointer',
        textDecoration: 'none'
    },
    link: {
        cursor: 'pointer'
    },
    longText: {
        whiteSpace: 'pre-wrap',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box'
    },
    rowHeightSmall: {
        whiteSpace: 'pre-wrap',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1
    },
    rowHeightMedium: {
        whiteSpace: 'pre-wrap',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3
    },
    rowHeightTall: {
        whiteSpace: 'pre-wrap',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 6
    },
    popover: {
        pointerEvents: 'none'
    },
    paper: {
        padding: theme.spacing(1),
        maxWidth: '50%'
    },
    logo: {
        height: '30px',
        width: '30px'
    },
    logoLarge: {
        height: '50px',
        width: '50px'
    },
    profilePicture: {
        height: '30px',
        width: '30px',
        borderRadius: '30px',
        marginRight: '10px'
    },
    breakWord: {
        wordBreak: 'break-word'
    },
    empty: {
        color: '#cccbca',
        display: 'flex',
        alignItems: 'center',
        height: '100%'
    },
    checkbox: {
        width: '20px',
        height: '20px'
    },
    'derived-metric-real-change-positive': {
        color: '#2DA72E'
    },
    'derived-metric-real-change-negative': {
        color: '#E01E5A'
    },
    'derived-metric-sparkline-container': {
        width: 100
    }
}));
const CompanyLogoCellWrapper = styled.div `
  margin-right: 10px;
`;
const textColorContentClass = 'text-content-default typography-label';
export const LoadingCellRenderer = () => {
    return (_jsx("div", { "data-testid": "LoadingCellRenderer", className: "animate-pulse h-8 w-full rounded-br20 bg-gray-200" }));
};
export const TagsCellRenderer = (props) => {
    const tags = get(props, 'value');
    return _jsx(Tags, { tags: tags });
};
export const TypedTagsCellRenderer = (props) => {
    const typedTags = defaultTo(props.value, []);
    const tags = typedTags.map((tag) => tag.tag_value);
    return _jsx(Tags, { tags: tags });
};
export const CompanyHighlightsCellRenderer = (props) => {
    return _jsx(CompanyHighlights, { ...props });
};
export const PeopleListCellRenderer = (props) => {
    return _jsx(PeopleList, { ...props });
};
export const CustomerTypeCellRenderer = (props) => {
    const customerType = defaultTo(props.value, []);
    if (!customerType) {
        return _jsx(NoDataSubRenderer, {});
    }
    return _jsx(Tags, { tags: customerType });
};
export const EmailsCellRenderer = (props) => {
    const emails = defaultTo(props.value, []);
    if (emails.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block ", children: _jsx(TruncatedList, { wrapperClassName: "-ml-p30", height: 54, children: emails.map((email, index) => (_jsx("div", { children: _jsx(Email, { email: email, collapsedInward: true }) }, index))) }) }));
};
export const InvestorsCellRenderer = (props) => {
    const investors = defaultTo(props.value, []).filter((investor) => investor?.name);
    if (investors.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: investors.map((investor, index) => {
                const investorId = getIdFromUrn(investor?.investor_urn);
                const typeToUse = (investor.type || investor.typename).toLowerCase();
                let url;
                if (investorId) {
                    url = `/dashboard/investor/${investorId}`;
                }
                else {
                    url = typeToUse ? `/dashboard/${typeToUse}/${investor.id}` : '';
                }
                return (_jsx("div", { "data-testid": "investor-item", children: typeToUse === 'person' ? (_jsx(PersonAttribute, { src: investor.logo_url ?? '', href: url, name: investor.name })) : (_jsx(CompanyAttribute, { src: investor.logo_url ?? '', href: url, name: investor.name })) }, index));
            }) }) }));
};
export const PeopleHighlightsCellRenderer = (props) => {
    const highlights = get(props, 'value');
    const allHighlights = getPeopleHighlights(highlights);
    if (allHighlights.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: allHighlights.map((highlight, index) => {
                return (_jsx(Badge, { leadingIcon: getHighlightIcon(highlight.category), label: highlight.category, color: "expressive-32", size: "large", suffix: highlight.count > 1 ? `${highlight.count}` : '' }, index));
            }) }) }));
};
export const EducationCellRenderer = (props) => {
    const education = defaultTo(props.value, []);
    const res = education
        .map((educationExperience) => educationExperience?.school?.name)
        .filter((item) => Boolean(item));
    if (education.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: res.map((item, index) => (_jsx("div", { children: _jsx(Tag, { label: item }) }, index))) }) }));
};
export const CompanyNameWithCheckboxCellRenderer = (props) => {
    const pageTitle = useStore((state) => state.pageTitle);
    const editStoreData = useStore((state) => state.editStoreData);
    const company = get(props.node.data, 'company');
    const currentUrl = window.location.pathname;
    const companyId = get(company, 'id', undefined);
    const companyName = get(company, 'name', '');
    const { urn, location: dashboardLocation } = useDashboardLocation();
    const isSearchPage = dashboardLocation == DashboardLocation.COMPANY_SEARCH;
    const classes = useStyles();
    const location = useLocation();
    const addSelectedRowIds = useTableStore((state) => state.addSelectedRowIds);
    const removeSelectedRowIds = useTableStore((state) => state.removeSelectedRowIds);
    const addNetNewRowIds = useTableStore((state) => state.addSelectedNetNewIds);
    const removeSelectedNetNewIds = useTableStore((state) => state.removeSelectedNetNewIds);
    const selectedRowIds = useTableStore((state) => state.selectedRowIds);
    const selectAllStateEnabled = useTableStore((state) => state.selectAllStateEnabled);
    const params = new URLSearchParams(window.location.search || '');
    const logoUrl = get(company, 'logo_url');
    // net new stuff
    const { hasNetNew, onClearNetNewByIds } = useNetNewCounts();
    const countExists = hasNetNew(urn);
    const netNewId = get(props.data, 'netNewId');
    const isNetNew = useMemo(() => {
        return !!(countExists && netNewId);
    }, [countExists, netNewId]);
    const context = props.context;
    const companyViewRecordResponse = useQuery(getCompaniesViewRecords, {
        variables: { ids: [companyId], extended: false },
        fetchPolicy: 'cache-only',
        skip: !companyId
    });
    if (!companyId)
        return _jsx(LoadingCellRenderer, {});
    const isCheckboxChecked = selectedRowIds.includes(companyId) || selectAllStateEnabled;
    const isExplorePage = isSearchPage && (urn === '' || !urn);
    // Since we don't have a boolean to check viewed state, we need to differentiate
    // between "data isn't ready yet in cache" and "no view record exists". If the data
    // response isn't available at all, we can assume it hasn't loaded in cache yet.
    // Unfortuntately companyViewRecordResponse.loading will return false before it's actually
    // done loading, so we can't depend on that.
    const viewRecordLoading = !companyViewRecordResponse.data;
    const companyViewRecord = companyViewRecordResponse.data?.getCompaniesByIds?.[0]?.viewRecord;
    const onCheckboxClick = (event) => {
        props.node.setSelected(event.target.checked);
        if (event.target.checked) {
            addSelectedRowIds([companyId]);
            netNewId && addNetNewRowIds([netNewId]);
        }
        else {
            removeSelectedRowIds([companyId]);
            netNewId && removeSelectedNetNewIds([netNewId]);
        }
    };
    return (_jsx("div", { className: "w-full flex flex-col", children: _jsxs("div", { className: "flex items-center gap-g50 w-full", children: [!currentUrl.includes('/person/') && (_jsx(CheckboxSubRenderer, { onCheckboxClick: onCheckboxClick, isChecked: isCheckboxChecked })), _jsx("div", { className: "flex w-full flex-row justify-between items-center", children: shouldLinkCompanyId(companyId) ? (_jsx(InternalLink, { onMouseDown: (e) => {
                            e.stopPropagation();
                            editStoreData('previousPageTitle', isExplorePage ? 'Explore' : pageTitle);
                            if (isNetNew && urn && netNewId)
                                onClearNetNewByIds({
                                    ids: [netNewId],
                                    savedSearchUrn: urn,
                                    type: SearchType.COMPANIES_LIST
                                });
                            // We explicitly mark viewed so that people opening in new tab
                            // will immediately see that company marked as viewed.
                            if (!viewRecordLoading && !companyViewRecord) {
                                context?.setViewed([companyId]);
                            }
                            analytics.trackCustomEvent({
                                event: CustomTrackEvent.COMPANY_PROFILE_CLICK,
                                properties: {
                                    companyId,
                                    openedFromLocation: EventLocations.GRID_TABLE
                                }
                            });
                        }, className: `${classes['internal-link']} typography-label text-content-strong`, to: {
                            pathname: `/dashboard/company/${companyId}`
                        }, "data-testid": "CompanyNameCellRenderer-Name", state: {
                            from: location.pathname,
                            params: params.toString()
                        }, openedFromLocation: EventLocations.GRID_TABLE_COMPANY_NAME_COMPANY_SEARCH, children: _jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "flex-shrink-0", children: _jsx(Avatar, { src: logoUrl, kind: "logo", size: "medium", alt: "company-logo" }) }), _jsx("div", { "data-testid": "CompanyNameCellRenderer-Name", className: "line-clamp-1 pl-p30", children: _jsx("p", { className: classNames('typography-label text-content-strong'), children: companyName }) })] }) })) : (_jsx("span", { children: companyName })) }), _jsx(UnviewedNewDot, { entityId: companyId, isUnviewed: !viewRecordLoading && !companyViewRecord, context: context, netNewId: isNetNew ? netNewId : undefined })] }) }));
};
export const PersonNameWithCheckboxCellRenderer = (props) => {
    const pageTitle = useStore((state) => state.pageTitle);
    const editStoreData = useStore((state) => state.editStoreData);
    const personId = get(props, 'data.person.id');
    const personUpdates = compact(get(props, 'data.person.updates') || []);
    const personName = get(props, 'data.person.fullName');
    const { urn, location } = useDashboardLocation();
    const { addSelectedRowIds, selectedRowIds, selectAllStateEnabled, removeSelectedRowIds } = useShallowTableStore([
        'addSelectedRowIds',
        'selectedRowIds',
        'selectAllStateEnabled',
        'removeSelectedRowIds'
    ]);
    const isCheckboxChecked = selectedRowIds.includes(personId) || selectAllStateEnabled;
    const isSearchPage = location == DashboardLocation.PEOPLE_SEARCH;
    const isExplorePage = isSearchPage && (urn === '' || !urn);
    // net new stuff
    const { hasNetNew, onClearNetNewByIds } = useNetNewCounts();
    const countExists = hasNetNew(urn);
    const netNewId = get(props.data, 'netNewId');
    const isNetNew = useMemo(() => {
        return !!(countExists && netNewId);
    }, [countExists, netNewId]);
    const showPersonUpdateChip = location === DashboardLocation.PERSON_LIST && personUpdates.length > 0;
    const jobUpdatedChip = (_jsx(Badge, { color: "informative", label: "Profile recently updated", size: "large" }));
    const tooltipText = (_jsx(_Fragment, { children: personUpdates.map((update, index) => (_jsxs(_Fragment, { children: [_jsx("span", { className: "typography-label-small-default", children: update.helperText ?? '' }, index), _jsx("br", {})] }))) }));
    const onCheckboxClick = (event) => {
        props.node.setSelected(event.target.checked);
        if (event.target.checked) {
            addSelectedRowIds([personId]);
        }
        else {
            removeSelectedRowIds([personId]);
        }
    };
    const logoUrl = get(props, 'data.person.profile_picture_url');
    const logoUrlGql = get(props, 'data.person.profilePictureUrl');
    const profilePictureUrl = logoUrl || logoUrlGql;
    return (_jsx("div", { children: _jsxs("div", { className: "flex justify-start items-start gap-g50 w-full", children: [_jsx(CheckboxSubRenderer, { isChecked: isCheckboxChecked, onCheckboxClick: onCheckboxClick }), _jsxs("div", { className: "flex flex-col gap-g30 w-full", children: [_jsxs("div", { className: "flex flex-row justify-between w-full items-center", children: [personId ? (_jsx(Link, { onClick: () => {
                                        editStoreData('previousPageTitle', isExplorePage ? 'Explore' : pageTitle);
                                        if (isNetNew && urn && netNewId)
                                            onClearNetNewByIds({
                                                ids: [netNewId],
                                                savedSearchUrn: urn,
                                                type: SearchType.PERSONS
                                            });
                                        analytics.trackCustomEvent({
                                            event: CustomTrackEvent.PERSON_PROFILE_CLICK,
                                            properties: {
                                                personId,
                                                openedFromLocation: EventLocations.GRID_TABLE
                                            }
                                        });
                                    }, to: `/dashboard/person/${personId}`, children: _jsxs("div", { className: "flex items-start", children: [_jsx(Avatar, { kind: "face", size: "medium", src: profilePictureUrl, alt: "person-profile-picture" }), _jsx("span", { className: "typography-label text-content-strong line-clamp-1 pl-p30", children: personName })] }) })) : (_jsx("span", { className: "typography-label", children: personName })), _jsx(UnviewedNewDot, { netNewId: isNetNew ? netNewId : undefined })] }), _jsx("div", { children: showPersonUpdateChip && (_jsx(Tooltip, { body: tooltipText, alignment: "right", children: _jsx("div", { className: "inline-block", children: jobUpdatedChip }) })) })] })] }) }));
};
export const LogoCellRenderer = (props) => {
    const gridRowHeight = useTableStore((state) => state.gridRowHeight);
    const logoUrl = get(props, 'data.company.logo_url');
    const isStealthCompany = get(props, 'node.data.company.name')?.startsWith('Stealth Company');
    const [imageError, setImageError] = React.useState(false);
    const classes = useStyles();
    const logoClassName = `${gridRowHeight > 65 ? classes.logoLarge : classes.logo}`;
    return logoUrl && isSafeHostedAsset(logoUrl) && !imageError ? (_jsx("div", { children: _jsx("img", { onError: () => setImageError(true), className: logoClassName, src: logoUrl }) })) : (_jsx("div", { children: 
        // Do not render until data is loaded
        props.node.data && (_jsx(OldAvatar, { className: logoClassName, variant: "rounded", children: isStealthCompany ? _jsx(SecurityIcon, {}) : _jsx(BusinessIcon, {}) })) }));
};
export const ProfilePictureCellRenderer = (props) => {
    const logoUrl = get(props, 'data.person.profile_picture_url');
    const logoUrlGql = get(props, 'data.profilePictureUrl');
    const profilePictureUrl = logoUrl || logoUrlGql;
    const [imageError, setImageError] = React.useState(false);
    const classes = useStyles();
    return profilePictureUrl &&
        isSafeHostedAsset(profilePictureUrl) &&
        !imageError ? (_jsx("div", { children: _jsx("img", { onError: () => setImageError(true), className: classes.profilePicture, src: profilePictureUrl }) })) : (_jsx("div", { children: 
        // Do not render until data is loaded
        props.node.data && (_jsx(OldAvatar, { className: classes.profilePicture, variant: "circular", children: _jsx(PersonIcon, {}) })) }));
};
export const CompanyNameCellRenderer = (props) => {
    const companyId = get(props, 'data.company.id');
    const companyName = get(props, 'data.company.name');
    const classes = useStyles();
    const location = useLocation();
    const params = new URLSearchParams(window.location.search || '');
    return (_jsx("div", { className: `${classes.longText} ${classes.ellipsis}`, children: shouldLinkCompanyId(companyId) ? (_jsx(Link, { className: classes.link, to: `/dashboard/company/${companyId}`, state: {
                from: location.pathname,
                params: params.toString(),
                openedFromLocation: EventLocations.GRID_TABLE_COMPANY_NAME_PERSON_SEARCH
            }, children: _jsxs("div", { className: "flex items-center", children: [_jsx(CompanyLogoCellWrapper, { children: _jsx(LogoCellRenderer, { ...props }) }), companyName] }) })) : (_jsx("span", { children: companyName })) }));
};
export const StageCellRenderer = (props) => {
    const fundingFieldValue = props.value;
    const stage = get(SearchValueToDisplayNameMap, fundingFieldValue || '');
    if (!stage) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(Badge, { label: stage, color: `expressive-${stringToExpressiveNumber(stage)}`, size: "large" }) }));
};
export const AffinityListsCellRenderer = (props) => {
    const company = get(props, 'node.data.company');
    const affinityLists = defaultTo(props.value, []);
    const { enabled: affinityIntegrationMocked } = useFlags(SPLITS.mockAffinityFrontend);
    const displayAffinityFieldsDrawer = useStore((state) => state.displayAffinityFieldsDrawer);
    const onClick = (e) => {
        e.preventDefault();
        displayAffinityFieldsDrawer(company?.id);
    };
    if (affinityIntegrationMocked) {
        return (_jsx(TruncatedList, { height: 54, children: _jsx(Tag, { label: "Affinity List 1", onClick: onClick }) }));
    }
    if (affinityLists.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: affinityLists.map((list, index) => {
                return (_jsx("div", { children: _jsx(Tag, { label: list.name, onClick: onClick }) }, index));
            }) }) }));
};
export const ListsCellRenderer = (props) => {
    const company = get(props, 'node.data.company');
    const allLists = defaultTo(props.value, []);
    const affinityLists = allLists.filter((list) => list?.isAffinityList);
    const otherLists = allLists.filter((list) => !list?.isAffinityList);
    const { enabled: affinityIntegrationMocked } = useFlags(SPLITS.mockAffinityFrontend);
    const displayAffinityFieldsDrawer = useStore((state) => state.displayAffinityFieldsDrawer);
    const onClick = (e) => {
        e.preventDefault();
        displayAffinityFieldsDrawer(company?.id);
    };
    if (allLists.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsxs(TruncatedList, { height: 54, children: [otherLists.map((list, index) => {
                    return (_jsx(Link, { to: `${DASHBOARD_ROUTE}${WATCHLIST_ROUTE}/${list.entityUrn}`, children: _jsx(Badge, { size: "large", borderRadius: "square", label: list.name }) }, index));
                }), affinityLists.map((list, index) => {
                    return (_jsx("div", { onClick: onClick, children: _jsx(Badge, { size: "large", label: list.name, trailingIcon: AffinityIcon }) }, index));
                })] }) }));
};
export const PeopleListsCellRenderer = (props) => {
    const allLists = defaultTo(props.value, []);
    if (allLists.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: allLists.map((list, index) => {
                return (_jsx(Link, { to: `${DASHBOARD_ROUTE}${PEOPLE_WATCHLIST_ROUTE}/${list.entityUrn}`, children: _jsx(Badge, { size: "large", borderRadius: "square", label: list.name }) }, index));
            }) }) }));
};
export const PersonExperienceDataCellRenderer = (props) => {
    const experience = get(props, 'node.data.person.experience');
    if (!experience || experience.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    let filteredExperiences = experience
        .filter((experienceObj) => experienceObj)
        .filter((experienceObj) => props.timeFrame === PersonExperienceDataTimeTypes.CURRENT
        ? experienceObj?.isCurrentPosition
        : !experienceObj?.isCurrentPosition);
    const currentExperienceCompanies = uniq(experience
        .filter((experience) => experience.isCurrentPosition)
        .map((experience) => experience.company?.id));
    if (props.timeFrame === PersonExperienceDataTimeTypes.PREVIOUS) {
        const lastExperience = filteredExperiences
            .filter((experience) => !currentExperienceCompanies.includes(experience.company?.id))
            .sort((left, right) => {
            return dayjsExt
                .utc(right.startDate)
                .diff(dayjsExt.utc(left.startDate));
        });
        filteredExperiences = lastExperience[0] ? [lastExperience[0]] : [];
    }
    if (props.timeFrame === PersonExperienceDataTimeTypes.ALL_PREVIOUS) {
        filteredExperiences = experience.filter((experience) => !currentExperienceCompanies.includes(experience.company?.id));
    }
    const currentDepartments = uniq(compact(filteredExperiences.map((experience) => experience.department)));
    switch (props.dataToFetch) {
        case PersonExperienceDataTypes.NAME: {
            const filteredExperiencesWithUniqueCompanies = Array.from(filteredExperiences.reduce((idMap, current) => {
                if (!current.company) {
                    return idMap;
                }
                if (idMap.has(current.company.id)) {
                    return idMap;
                }
                idMap.set(current.company.id, current);
                return idMap;
            }, new Map()), ([, value]) => value);
            const data = filteredExperiencesWithUniqueCompanies.map((experience) => {
                return {
                    text: experience.company.name ?? 'Unknown company',
                    imageUrl: experience.company.logoUrl,
                    id: experience.company.id
                };
            });
            if (data.length === 0) {
                return _jsx(NoDataSubRenderer, {});
            }
            return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: data.map((item, index) => (_jsx("div", { children: _jsx(CompanyAttribute, { href: `/dashboard/company/${item.id}`, disabled: !shouldLinkCompanyId(item.id), name: item.text, src: item.imageUrl ?? '' }) }, index))) }) }));
        }
        case PersonExperienceDataTypes.TITLE:
            return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: filteredExperiences.map((experience, index) => (_jsx("div", { children: _jsx(Tag, { label: experience?.title ?? 'Unknown' }) }, index))) }) }));
        case PersonExperienceDataTypes.DEPARTMENT:
            return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: currentDepartments.map((department, index) => (_jsx("div", { children: _jsx(Tag, { label: department, color: `expressive-${stringToExpressiveNumber(department)}` }) }, index))) }) }));
        case PersonExperienceDataTypes.TAGS: {
            const tags = filteredExperiences.reduce((acc, experience) => {
                if (!experience || !experience.company) {
                    return acc;
                }
                if (!experience.company.tags ||
                    experience.company.tags?.length === 0) {
                    return acc;
                }
                const companyTags = experience.company.tags
                    .map((x) => {
                    if (x)
                        return x.displayValue;
                })
                    .filter((x) => x);
                return [
                    ...acc,
                    ...compact(companyTags).map((tag) => ({ text: tag }))
                ];
            }, []);
            if (tags.length === 0) {
                return _jsx(NoDataSubRenderer, {});
            }
            return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: tags.map((tag, index) => (_jsx("div", { children: _jsx(Tag, { label: tag.text, color: `expressive-${stringToExpressiveNumber(tag.text)}` }) }, index))) }) }));
        }
        default:
            return _jsx(NoDataSubRenderer, {});
    }
};
export const PersonNameCellRenderer = (props) => {
    const personId = get(props, 'data.person.id') || get(props, 'data.id');
    const personName = get(props, 'value');
    const classes = useStyles();
    return (_jsx("div", { className: `${classes.longText} ${classes.ellipsis} ${textColorContentClass}`, children: personId ? (_jsx(Link, { onClick: () => {
                analytics.trackCustomEvent({
                    event: CustomTrackEvent.PERSON_PROFILE_CLICK,
                    properties: {
                        personId,
                        openedFromLocation: EventLocations.GRID_TABLE
                    }
                });
            }, className: classNames(classes.link, 'no-underline'), to: `/dashboard/person/${personId}`, children: _jsxs("div", { className: "flex items-center", children: [_jsx(ProfilePictureCellRenderer, { ...props }), _jsx("div", { children: _jsxs("span", { className: "underline typography-label", children: [" ", personName] }) })] }) })) : (_jsx("span", { className: "typography-label", children: personName })) }));
};
export const DateCellRenderer = (props) => {
    return _jsx(DateCell, { ...props });
};
export const NumberCellRenderer = (props) => {
    const number = get(props, 'value');
    if (number !== undefined && number !== null) {
        return (_jsx("div", { className: "inline-block", children: _jsx("span", { className: "typography-label-default-number text-content-default", children: addCommasToNumber(number.toFixed(0)) }) }));
    }
    return _jsx(NoDataSubRenderer, {});
};
export const PhoneNumbersCellRenderer = (props) => {
    const phoneNumbers = defaultTo(props.value, []);
    if (phoneNumbers.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: phoneNumbers.map((phoneNumber, index) => (_jsx("div", { children: _jsx("span", { className: "typography-label-default-number text-content-default", children: phoneNumber }) }, index))) }) }));
};
export const LongTextCellRenderer = (props) => {
    return _jsx(LongText, { ...props });
};
export const LinkCellRenderer = (props) => {
    const url = get(props, 'value');
    const isCompany = Boolean(props.data?.company?.id);
    const { urn } = useDashboardLocation();
    const { onClearNetNewByIds } = useNetNewCounts();
    const netNewId = props.data.netNewId;
    const context = props.context;
    const onLinkClicked = () => {
        const field = (props.column?.getDefinition()).field;
        analytics.trackCustomEvent({
            event: CustomTrackEvent.EXTERNAL_LINK_CLICKED,
            properties: {
                entityField: field,
                url
            }
        });
        if (isCompany) {
            context?.setViewed([props.data.company.id]);
        }
        if (urn && netNewId)
            onClearNetNewByIds({
                ids: [netNewId],
                savedSearchUrn: urn,
                type: isCompany ? SearchType.COMPANIES_LIST : SearchType.PERSONS
            });
    };
    if (url) {
        return (_jsx(LinkAttribute, { onClick: onLinkClicked, link: url, dataTestId: "LinkCellRenderer-Link" }));
    }
    return _jsx(NoDataSubRenderer, {});
};
export const CompanyTypeCellRenderer = (props) => {
    const companyType = props.value;
    return companyType ? (_jsx(Tag, { label: get(SearchValueToDisplayNameMap, companyType, companyType), color: `expressive-${stringToExpressiveNumber(companyType)}` })) : (_jsx(NoDataSubRenderer, {}));
};
export const EmailForPeopleSearchCellRenderer = (props) => {
    const personId = props.node.data?.person?.id;
    const { data: personContactInformation } = useQuery(getPersonContactInformation, {
        variables: {
            id: personId
        },
        fetchPolicy: 'cache-only'
    });
    const emails = compact(personContactInformation?.getPersonById?.contact?.emails || []);
    const emailsEnrichedAt = personContactInformation?.getPersonById?.emailEnrichmentStatus?.enrichedAt;
    const isRequestable = Boolean(emails.length === 0 && !emailsEnrichedAt);
    const isUnavailable = Boolean(emails.length === 0 && emailsEnrichedAt);
    const { show } = useModal();
    if (isUnavailable) {
        return _jsx(NoDataSubRenderer, {});
    }
    if (isRequestable) {
        return (_jsx(Tag, { label: "Request", onClick: () => show(_jsx(PersonEmailUnlockModal, { personId: personId })) }));
    }
    return (_jsx("div", { className: "inline-flex", children: _jsx(TruncatedList, { height: 54, children: emails.map((email, index) => (_jsx("div", { children: _jsx(Email, { email: email, collapsedInward: true }) }, index))) }) }));
};
export const EventTypeCellRenderer = (props) => {
    const event_type_str = get(props, 'value');
    const eventDate = new Date(get(props, 'data.observed_at'));
    const classes = useStyles();
    if (event_type_str) {
        return (_jsxs("div", { className: `${classes.ellipsis} flex items-center`, children: [eventDate.toLocaleString('en-US', {
                    timeZone: 'UTC',
                    month: 'short',
                    day: 'numeric'
                }), ": ", get(EventTypeToDescriptionMapping, event_type_str)] }));
    }
    return _jsx(React.Fragment, {});
};
export const FundingCellRenderer = (props) => {
    const fundingField = props.colDef?.field;
    const fundingFieldValue = props.value;
    const fundingAttributeNullStatus = props.node.data?.company?.funding_attribute_null_status;
    if (!fundingFieldValue && fundingAttributeNullStatus) {
        return (_jsx("div", { className: "typography-label", children: get(SearchValueToDisplayNameMap, fundingAttributeNullStatus) }));
    }
    if (fundingField === 'company.last_funding_at') {
        return _jsx(DateCellRenderer, { ...props });
    }
    if (fundingField === 'company.funding_total' ||
        fundingField === 'company.last_funding_total' ||
        fundingField === 'company.funding_per_employee' ||
        fundingField?.includes('funding_total')) {
        return _jsx(MoneyCellRenderer, { ...props });
    }
    if (fundingField === 'company.investors' &&
        (!fundingFieldValue ||
            (isArray(fundingFieldValue) && fundingFieldValue.length === 0))) {
        return (_jsx("div", { className: "typography-label", children: get(SearchValueToDisplayNameMap, fundingAttributeNullStatus) }));
    }
    return _jsx(LongTextCellRenderer, { ...props });
};
export const AcceleratorsCellRenderer = (props) => {
    const accelerators = defaultTo(props.value, []);
    if (accelerators.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: accelerators.map((accelerator, index) => {
                return (_jsx("div", { children: _jsx(Tag, { label: accelerator }) }, index));
            }) }) }));
};
export const MoneyCellRenderer = (props) => {
    const amount = toInteger(get(props, 'value'));
    if (amount !== undefined) {
        return (_jsx("div", { className: "inline-block", children: _jsxs("div", { className: "flex items-center gap-g10", children: [_jsx("span", { className: "typography-label text-content-weak", children: "$" }), _jsx("p", { className: "typography-label-default-number text-content-default", children: truncateMoneyValue(amount, undefined, false) })] }) }));
    }
    return _jsx(NoDataSubRenderer, {});
};
export const NoDataSubRenderer = ({ type }) => {
    return (_jsx("div", { className: "inline-block", children: _jsx("span", { className: "typography-paragraph text-content-muted", children: type === 'connections' ? 'Not in network' : 'No data' }) }));
};
export const DerivedMetricCellRenderer = (props) => {
    const field = props.column?.getColDef().field;
    const value = toNumber(props.value);
    const metricType = field?.includes('.real_') // the type of metric, e.g. real growth, percent growth
        ? DerivedMetricType.RealChange
        : field?.includes('.percent_')
            ? DerivedMetricType.PercentChange
            : DerivedMetricType.Snapshot;
    if ((props.value === null || props.value === undefined || isNaN(props.value)) &&
        metricType !== DerivedMetricType.Snapshot) {
        return _jsx(NoDataSubRenderer, {});
    }
    if (metricType === DerivedMetricType.RealChange) {
        if (value > 0) {
            return (_jsx("div", { className: "inline-block", children: _jsx(Badge, { color: "positive", size: "large", intensity: "subtle", label: `▲ ${addCommasToNumber(value.toFixed(0))}` }) }));
        }
        if (value < 0) {
            return (_jsx("div", { className: "inline-block", children: _jsx(Badge, { color: "negative", size: "large", intensity: "subtle", label: `▼ ${addCommasToNumber((value * -1).toFixed(0))}` }) }));
        }
        return _jsx(NumberCellRenderer, { ...props });
    }
    if (metricType === DerivedMetricType.PercentChange) {
        if (value > 0) {
            return (_jsx("div", { className: "inline-block", children: _jsx(Badge, { color: "positive", size: "large", intensity: "subtle", label: `▲ ${addCommasToNumber(value.toFixed(2))}%` }) }));
        }
        if (value < 0) {
            return (_jsx("div", { className: "inline-block", children: _jsx(Badge, { color: "negative", size: "large", intensity: "subtle", label: `▼ ${(value * -1).toFixed(2)}%` }) }));
        }
        if (value === 0) {
            return (_jsx("div", { className: "inline-block", children: _jsx(Badge, { color: "neutral", size: "large", intensity: "subtle", label: `${value.toFixed(0)}%` }) }));
        }
    }
    return (_jsx("span", { className: "typography-label-default-number text-content-default", children: isNaN(value) ? '0' : addCommasToNumber(value.toFixed(0)) }));
};
export const RelevanceScoreCellRenderer = (props) => {
    return _jsx(RelevanceScore, { ...props });
};
const CheckboxSubRenderer = ({ isChecked, onCheckboxClick }) => {
    const { selectAllStateEnabled } = useShallowTableStore([
        'selectAllStateEnabled'
    ]);
    return (_jsx(Checkbox, { dataTestId: "company-select-checkbox", checked: isChecked, disabled: selectAllStateEnabled, onChange: onCheckboxClick }));
};
export const UserConnectionsCellRenderer = (props) => {
    return _jsx(UserConnections, { ...props });
};
export const CorrespondencePersonCellRenderer = (props) => {
    const emailAddress = props.value;
    const authState = useAuthState();
    const customerUrn = authState.userMetadata?.customer_urn ?? '';
    const companyId = get(props, 'data.company.id');
    const personId = get(props, 'data.person.id');
    const { data: usersData } = useQuery(GET_ALL_TEAM_MEMBERS_WITH_INTEGRATED_EMAILS, {
        variables: { customer_urn: customerUrn },
        fetchPolicy: 'cache-only'
    });
    if (!emailAddress) {
        return _jsx(NoDataSubRenderer, {});
    }
    const displayName = usersData?.getAllTeamMembersWithIntegratedEmailsByCustomer?.find((user) => user?.integratedEmails?.some((integratedEmail) => integratedEmail === emailAddress))?.name;
    return (_jsx("div", { className: "flex", children: _jsx("span", { title: emailAddress, children: _jsx(PersonAttribute, { src: "", href: companyId
                    ? `/dashboard/company/${companyId}?selectedTab=${CompanyNavbarOptions.CONNECTIONS}`
                    : `/dashboard/person/${personId}`, name: displayName ?? emailAddress }) }) }));
};
export const CorrespondenceDateCellRenderer = (props) => {
    const date = props.value;
    const companyId = get(props, 'data.company.id');
    return date ? (_jsx("a", { href: `/dashboard/company/${companyId}?selectedTab=${CompanyNavbarOptions.CONNECTIONS}`, children: _jsx(Tag, { leadingIcon: CalendarDaysIcon, onClick: () => null, label: dayjsExt.utc(date).format('MM/DD/YYYY') }) })) : (_jsx(NoDataSubRenderer, {}));
};
export const ViewRecordCellRenderer = (props) => {
    const viewRecord = props.value;
    return !viewRecord ? (_jsx("div", { children: "Unviewed" })) : (_jsx("div", { className: "text-gray-400", children: dayjsExt.utc(viewRecord.updated_at).format('MM/DD/YYYY') }));
};
export const LocationCellRenderer = (props) => {
    const location = props.value;
    if (!location) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(Tag, { label: location }) }));
};
const AutoCompleteWrapper = styled.div `
  width: 150%;
  border-width: 0px;
  .MuiInput-underline:before,
  .MuiInput-underline:hover,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-width: 0px;
  }
`;
export const SelectEditCellRenderer = React.forwardRef((props, ref) => {
    const [value, setValue] = React.useState(props.value);
    const [inputValue, setInputValue] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const onChangeHandler = (_, newValue) => {
        setValue(newValue.value);
    };
    const onInputChangeHandler = (_, inputValue) => {
        setInputValue(inputValue);
    };
    /* Component Editor Lifecycle methods */
    React.useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            }
        };
    });
    return (_jsx(AutoCompleteWrapper, { children: _jsx(Autocomplete, { open: open, style: { padding: '0 10px', width: '150%' }, classes: {
                input: 'typography-label-small',
                option: 'typography-label'
            }, onOpen: () => setOpen(true), onClose: () => setOpen(false), options: props.options, getOptionLabel: (option) => option.label, 
            //eslint-disable-next-line
            //@ts-ignore
            value: value, onChange: onChangeHandler, inputValue: inputValue, onInputChange: onInputChangeHandler, disableClearable: true, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: {
                    className: 'text-xs'
                }, variant: "standard", inputProps: {
                    ...params.inputProps,
                    className: 'typography-label',
                    disableUnderline: true
                }, placeholder: props.placeholder || 'Select options' })) }) }));
});
const UserCellRenderer = (props) => {
    const value = props.value;
    if (!value) {
        return _jsx(NoDataSubRenderer, {});
    }
    return _jsx(Tag, { label: value.name ?? '', truncate: true }, value?.entityUrn);
};
export const CellRenderers = {
    LogoCellRenderer: LogoCellRenderer,
    CompanyNameCellRenderer: CompanyNameCellRenderer,
    PersonNameCellRenderer: PersonNameCellRenderer,
    DateCellRenderer: DateCellRenderer,
    CompanyTypeCellRenderer: CompanyTypeCellRenderer,
    LinkCellRenderer: LinkCellRenderer,
    LongTextCellRenderer: LongTextCellRenderer,
    CompanyNameWithCheckboxCellRenderer: CompanyNameWithCheckboxCellRenderer,
    PersonNameWithCheckboxCellRenderer: PersonNameWithCheckboxCellRenderer,
    EventTypeCellRenderer: EventTypeCellRenderer,
    FundingCellRenderer: FundingCellRenderer,
    MoneyCellRenderer: MoneyCellRenderer,
    ProfilePictureCellRenderer: ProfilePictureCellRenderer,
    DerivedMetricCellRenderer: DerivedMetricCellRenderer,
    PersonExperienceDataCellRenderer: PersonExperienceDataCellRenderer,
    UserConnectionsCellRenderer: UserConnectionsCellRenderer,
    ViewRecordCellRenderer: ViewRecordCellRenderer,
    SelectEditCellRenderer: SelectEditCellRenderer,
    RelevanceScoreCellRenderer: RelevanceScoreCellRenderer,
    LocationCellRenderer: LocationCellRenderer,
    PeopleListCellRenderer: PeopleListCellRenderer,
    CompanyHighlightsCellRenderer: CompanyHighlightsCellRenderer,
    TypedTagsCellRenderer: TypedTagsCellRenderer,
    TagsCellRenderer: TagsCellRenderer,
    StageCellRenderer: StageCellRenderer,
    AffinityListsCellRenderer: AffinityListsCellRenderer,
    ListsCellRenderer: ListsCellRenderer,
    CustomerTypeCellRenderer: CustomerTypeCellRenderer,
    InvestorsCellRenderer: InvestorsCellRenderer,
    NumberCellRenderer: NumberCellRenderer,
    AcceleratorsCellRenderer: AcceleratorsCellRenderer,
    PhoneNumbersCellRenderer: PhoneNumbersCellRenderer,
    PeopleHighlightsCellRenderer: PeopleHighlightsCellRenderer,
    EducationCellRenderer: EducationCellRenderer,
    CorrespondenceDateCellRenderer: CorrespondenceDateCellRenderer,
    CorrespondencePersonCellRenderer: CorrespondencePersonCellRenderer,
    BlankCellRenderer: BlankCellRenderer,
    EmailsCellRenderer: EmailsCellRenderer,
    EmailForPeopleSearchCellRenderer: EmailForPeopleSearchCellRenderer,
    LoadingCellRenderer: LoadingCellRenderer,
    PeopleListsCellRenderer: PeopleListsCellRenderer,
    UserCellRenderer: UserCellRenderer,
    CompanyEmployeesExperienceCellRenderer: CompanyEmployeesExperienceCellRenderer
};
